import { computed } from 'vue';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { signOut } from 'firebase/auth';
import { auth } from '@/firebase/config';

export default function usePlayerState() {
  const store = useStore();
  const router = useRouter();

  // Read theme from the cookie
  const theme = computed(() => {
    const cookieTheme = Cookies.get('theme');
    return cookieTheme || 'light'; // Default to 'light' if no cookie is set
  });

  // Player data from Vuex store
  const playerData = computed(() => store.getters.curPlayerData);

  // Player data update status from Vuex store
  const playerUpdateStatus = computed(() => store.state.playerListenerStatus);

  /**
   * Function to wait for player data to finish loading
   * @returns {Promise<boolean>} - Resolves to `true` if successful, otherwise handles errors or timeouts.
   */
  const waitForPlayerData = async () => {
    const maxWaitTime = 10000; // 10 seconds
    const interval = 100;
    let waitedTime = 0;

    while (store.state.playerListenerStatus === 'loading' && waitedTime < maxWaitTime) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      waitedTime += interval;
    }

    const finalStatus = store.state.playerListenerStatus;

    if (finalStatus === 'success') {
      return true; // Player data is ready
    } else if (finalStatus === 'error') {
      alert('Error: Unable to load player data.');
      await signOut(auth);
      router.push({ name: 'landing' });
      return false;
    } else {
      console.error('Timeout waiting for player data to resolve.');
      alert('System timeout. Please try again.');
      await signOut(auth);
      router.push({ name: 'landing' });
      return false;
    }
  };

  return {
    theme,
    playerData,
    playerUpdateStatus,
    waitForPlayerData,
  };
}
