<template>
  <Smash :isVisible="loadingData" labelText="Loading events" labelSubtext="almost done" />
  <div class="buttonBlock">
    <button class="button-large-small-text" @click="goMyEvents" style="margin-right: 4px;">
      <i class="fa-solid fa-pickleball"></i>My Upcoming
    </button>
    <button class="button-large-small-text" @click="goHistory" style="margin-right: 4px;">
      <i class="fa-solid fa-clock-rotate-left"></i>My History
    </button>
  </div>
  <div class="normal-heading" style="margin-bottom: 0; padding-bottom: 0;"><i class="fa-solid fa-calendar" style="color: var(--text-color)"></i> Event Calendar</div>

  <div class="calendar-container">
    <div class="calendar-header">
      <div v-for="(day, index) in headerDays" :key="index" class="day-header">{{ day }}</div>
    </div>
    <div class="calendar-weeks">
      <div v-for="(week, index) in visibleWeeks" :key="index" class="week-row">
        <div v-for="date in week" :key="date" @click="isPastDay(date) ? '' : goDay(date)" class="day-cell" :class="{ 'current-day': isToday(date), 'updated': isUpdated(date), 'past-day': isPastDay(date) }" :disabled="isPastDay(date)">
          <div class="date-container">
            <div class="day-number">{{ date.getDate() }}</div>
            <div class="month-abbr">{{ formatMonth(date) }}</div>
          </div>
          <div v-if="getTotalEventsForDay(formatDate(date))" class="event-count">{{ getTotalEventsForDay(formatDate(date)) }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="calendar-controls">
    <button :class="['button-large-small-text', { 'button-large-small-text-disabled': isBackDisabled }]" :disabled="isBackDisabled" @click="navigateBack">
      <i class="fa-solid fa-backward"></i>Back
    </button>
    <button :class="['button-large-small-text', { 'button-large-small-text-disabled': isForwardDisabled }]" :disabled="isForwardDisabled" @click="navigateForward">
      Forward
      <i class="fa-solid fa-forward" style="padding-left: var(--padding-tiny);"></i>
    </button>
  </div>
</template>


<script>
import { ref, computed, watch, onMounted, watchEffect } from 'vue'
import useEventCounts from '@/composables/getEventCounts'
import useEventCountsPrivate from '@/composables/getEventCountsPrivate'
import useEventCountsLocation from '@/composables/getEventCountsLocation'
import Cookies from 'js-cookie'
import { format, addDays, startOfWeek } from 'date-fns'
import useNoDoubletap from '@/composables/noDoubletap'
import usePlayerState from '@/composables/usePlayerState'
import Smash from '@/components/Smash.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'Calendar',
  components: {
    Smash,
  },
  props: {
    locationId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter()
    const { theme, playerData, waitForPlayerData } = usePlayerState()
    document.documentElement.setAttribute('data-theme', theme.value)
    const loadingData = ref(true)

    useNoDoubletap() // prevents double-tap zoom on iOs, etc
    const savedLanguage = Cookies.get('language')
    const daysOfWeek = savedLanguage === 'es' ? ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'] : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const shortDaysOfWeek = savedLanguage === 'es' ? ['D', 'L', 'M', 'X', 'J', 'V', 'S'] : ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    const user_slug = ref('')
    const formatDate = (date) => format(date, 'yyyy-MM-dd')

    const today = startOfWeek(new Date()) // Start from the Sunday of the current week
    const endOfRange = addDays(today, 60) // Define a fixed 60-day range

    const isBackDisabled = ref(true)
    const isForwardDisabled = ref(false)
    const currentWeekIndex = ref(0)
    const eventCounts = ref([]) // Initialize as empty
    const eventCountsPrivate = ref([]) // Initialize as empty
    // Generate all weeks for the 60-day range upfront
    const allWeeks = generateWeeks(today, endOfRange)

    const visibleWeeks = computed(() => allWeeks.slice(currentWeekIndex.value, currentWeekIndex.value + 5))

    function generateWeeks(startDate, endDate) {
      const weeks = []
      let currentDate = new Date(startDate)

      while (currentDate <= endDate) {
        const week = []
        for (let day = 0; day < 7; day++) {
          week.push(new Date(currentDate))
          currentDate.setDate(currentDate.getDate() + 1)
        }
        weeks.push(week)
      }

      return weeks
    }

    const navigateForward = () => {
      if (currentWeekIndex.value + 5 >= allWeeks.length) {
        isForwardDisabled.value = true
        return
      }

      currentWeekIndex.value++
      isBackDisabled.value = false

      if (currentWeekIndex.value + 5 >= allWeeks.length) {
        isForwardDisabled.value = true
      }
    }

    const navigateBack = () => {
      if (currentWeekIndex.value === 0) {
        isBackDisabled.value = true
        return
      }

      currentWeekIndex.value--
      isForwardDisabled.value = false

      if (currentWeekIndex.value === 0) {
        isBackDisabled.value = true
      }
    }

    // Function to get total events for a specific day
    const getTotalEventsForDay = (dateStr) => {
      const eventsForDay = eventCounts.value.filter((event) => event.date === dateStr)
      return eventsForDay.reduce((sum, event) => sum + event.eventCount, 0)
    }

    const isToday = (date) => {
      const current = new Date()
      return date.getDate() === current.getDate() && date.getMonth() === current.getMonth() && date.getFullYear() === current.getFullYear()
    }

    const isPastDay = (date) => {
      const today = format(new Date(), 'yyyy-MM-dd') // Format today's date as 'yyyy-MM-dd'
      const formattedDate = format(date, 'yyyy-MM-dd') // Format the passed date as 'yyyy-MM-dd'
      return formattedDate < today
    }

    const goDay = (date) => {
      // alert('clicked ' + formatDate(date))
      if (props.locationId) {
        router.push(`/eventday/${formatDate(date)}/${props.locationId}`)
      } else {
        router.push('eventday/' + formatDate(date))
      }
    }
    const formatMonth = (date) => date.toLocaleString('default', { month: 'short' })

    const headerDays = computed(() => (window.innerWidth < 350 ? shortDaysOfWeek : daysOfWeek))

    const isUpdated = (date) => {
      return updatedDays.value.has(formatDate(date))
    }

    const updatedDays = ref(new Set())
    const loaded = ref(false) // Flag to track initial load

    // Watch for changes in event counts
    watch(eventCounts, (newCounts, oldCounts) => {
      if (!loaded.value) {
        loaded.value = true
        return
      }

      // Compare new and old counts by date
      const newCountByDate = newCounts.reduce((acc, event) => {
        acc[event.date] = (acc[event.date] || 0) + event.eventCount
        return acc
      }, {})

      const oldCountByDate = oldCounts
        ? oldCounts.reduce((acc, event) => {
            acc[event.date] = (acc[event.date] || 0) + event.eventCount
            return acc
          }, {})
        : {}

      Object.keys(newCountByDate).forEach((date) => {
        if (newCountByDate[date] !== oldCountByDate[date]) {
          // Mark date as updated
          updatedDays.value.add(date)
          // Remove the update marker after animation duration (e.g., 1 second)
          setTimeout(() => updatedDays.value.delete(date), 1000)
        }
      })
    })

    onMounted(async () => {
      loadingData.value = true
      const success = await waitForPlayerData()
      loadingData.value = false
      if (success) {
        user_slug.value = playerData.value.id
        const result = props.locationId ? useEventCountsLocation(props.locationId) : useEventCounts(user_slug.value)
        watch(
          result.eventCounts,
          (newCounts) => {
            eventCounts.value = newCounts
          },
          { immediate: true }
        )
        // now check private
        const resultPrivate = useEventCountsPrivate(user_slug.value)
        watch(
          resultPrivate.eventCountsPrivate,
          (newCountsPrivate) => {
            eventCountsPrivate.value = newCountsPrivate
          },
          { immediate: true }
        )
      }
    })

    watch(
      eventCountsPrivate,
      (newCountsPrivate) => {
        if (newCountsPrivate.length > 0 && eventCounts.value.length > 0) {
          // Merge eventCountsPrivate into eventCounts when both have data
          eventCounts.value = eventCounts.value.concat(newCountsPrivate)
        }
      },
      { immediate: true }
    )
    const goMyEvents = () => {
      router.push('/myevents/')
      // router.back()
    }
    const goHistory = () => {
      router.push('/myhistory/')
      // router.back()
    }
    return {
      daysOfWeek,
      shortDaysOfWeek,
      visibleWeeks,
      isToday,
      formatMonth,
      headerDays,
      getTotalEventsForDay,
      isUpdated,
      isBackDisabled,
      isForwardDisabled,
      navigateForward,
      navigateBack,
      formatDate,
      loadingData,
      goDay,
      isPastDay,
      goMyEvents,
      goHistory,
    }
  },
}
</script>


<style>
.calendar-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--margin-medium);
  width: 96%;
}

.calendar-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: 500;
  gap: 0px;
}

.calendar-weeks {
  display: flex;
  flex-direction: column;
}

.week-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
}

.day-cell {
  border: 1px solid var(--button-color);
  border-radius: var(--radius-small);
  padding: var(--padding-tiny);
  text-align: center;
  min-height: var(--calendar-cell-height);
  position: relative;
}

.day-cell:hover {
  border: 2px solid var(--button-disabled-color);
  background-color: var(--list-background-odd);
}

.current-day {
  background-color: var(--list-background-odd);
  border-color: var(--list-background-odd);
}

.current-day:hover {
  border: 2px solid var(--button-disabled-color);
  background-color: var(--list-background-odd);
}

.date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--margin-tiny); /* Space for the event count at the bottom */
}

.day-header {
  font-size: var(--font-size-heading);
  color: var(--subtle-text-color);
}

.day-number {
  font-size: var(--font-size-heading); /* Larger to make the day number more prominent */
  font-weight: 500;
  color: var(--button-color);
}

.month-abbr {
  font-size: var(--font-size-regular); /* Smaller size for month abbreviation */
  color: var(--button-color);
}

.event-count {
  margin: auto;
  width: var(--event-count-size);
  height: var(--event-count-size);
  border-radius: 50%;
  background-color: var(--input-background-color);
  color: var(--input-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-subheading);
  font-weight: 900;
}

.updated {
  animation: flash 1s ease-out;
}

@keyframes flash {
  0% {
    background-color: var(--button-color);
    transform: scale(1.1);
  }
  100% {
    background-color: transparent;
    transform: scale(1);
  }
}

.calendar-controls {
  display: flex;
  justify-content: space-between; /* Ensures the buttons are spaced left/right */
  width: var(--width-default);
  padding: 0;
  margin-top: 0;
}

.calendar-controls button {
  width: 49%;
}

.past-day {
  opacity: 0.4; /* Optional: Make past days slightly transparent for better UX */
}
.past-day:hover {
  background-color: var(--button-disabled);
  border: 1px solid var(--button-color);
}
</style>
