import { ref, onUnmounted, getCurrentInstance } from 'vue'
import { db } from '@/firebase/config'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { format, addDays } from 'date-fns'

export default function useEventCounts(user_slug) {
    const eventCounts = ref([])
    const locationsUnsubscribe = ref(null)
    const eventsUnsubscribe = ref(null)

    const fetchEventCounts = () => {
        const today = format(new Date(), 'yyyy-MM-dd')
        const futureDate = format(addDays(new Date(), 60), 'yyyy-MM-dd')

        // Step 1: Query favorite locations
        const playerLocationsRef = collection(db, 'playerLocations')
        const locationsQuery = query(
            playerLocationsRef,
            where('playerId', '==', user_slug),
            where('removed', '!=', true)
        )

        locationsUnsubscribe.value = onSnapshot(locationsQuery, (snapshot) => {
            const locationIds = snapshot.docs.map(doc => doc.data().locationId)

            if (locationIds.length === 0) {
                eventCounts.value = [] // No favorite locations, reset event counts
                return
            }

            // Step 2: Query future events for favorite locations
            const eventsRef = collection(db, 'events')
            const eventsQuery = query(
                eventsRef,
                where('startDate', '>=', today),
                where('startDate', '<=', futureDate),
                where('invite', '==', false),
                where('location', 'in', locationIds) // 'location' refers to locationId
            )

            // Unsubscribe from any previous events listener before setting a new one
            if (eventsUnsubscribe.value) eventsUnsubscribe.value()

            eventsUnsubscribe.value = onSnapshot(eventsQuery, (eventSnapshot) => {
                const counts = {}

                eventSnapshot.forEach((doc) => {
                    const { startDate, location, deleted } = doc.data()
                    
                    // Skip events that are marked as deleted
                    if (deleted === true) return

                    const key = `${startDate}_${location}`

                    if (!counts[key]) {
                        counts[key] = { date: startDate, locationId: location, eventCount: 0 }
                    }
                    counts[key].eventCount += 1
                })

                eventCounts.value = Object.values(counts)
            })
        })
    }

    fetchEventCounts()

    // Cleanup both listeners on unmount
    if (getCurrentInstance()) {
        onUnmounted(() => {
            if (locationsUnsubscribe.value) locationsUnsubscribe.value()
            if (eventsUnsubscribe.value) eventsUnsubscribe.value()
        })
    }

    return { eventCounts }
}
