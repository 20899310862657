<template>
  <div class="termsPrivacy">
    <span class="text-link" @click="handleTerms">{{ t('termsOfUse') }}</span> |
    <span class="text-link" @click="handlePrivacy">{{ t('privacyPolicy') }}</span>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default {
  name: 'TermsPrivacy',
  setup() {
    const router = useRouter()
    const { t } = useI18n()
    const handleTerms = () => {
      router.push('/terms')
    }
    const handlePrivacy = () => {
      router.push('/privacy')
    }

    return {
      t,
      handleTerms,
      handlePrivacy,
    }
  },
}
</script>

<style scoped>
.termsPrivacy {
  margin-top: var(--margin-medium);
  margin-bottom: var(--margin-tiny);
  font-size: var(--font-size-regular);
  color: var(--text-color);
}


</style>
