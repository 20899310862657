<template>
  <div class="outer-wrapper">
    <div :class="stretch ? 'input-block-signin stretch-item' : 'input-block-signin'" style="margin-top: var(--margin-small);">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input @input="inputChanged" style="text-align: left;" type="text" inputmode="text" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" name="player" :placeholder="loadingPl ? 'Loading players...' :'Enter player name' " maxlength="13" v-model="playerQuery" :disabled="loadingPl" />
    </div>
    <div :class="stretch ? 'suggestions-container stretch-item' : 'suggestions-container' " v-if="playerQuery && !playerSelected">
      <div class="suggestion-item" v-for="p in playersFiltered" :key="p" @click="handleSelectPlayer(p)">@{{ p }}</div>
    </div>
  </div>
</template>
  
<script>
import { ref, computed, watch, nextTick } from 'vue'
import slugify from 'slugify'
import { useStore } from 'vuex'

export default {
  name: 'PlayerPicker',
  props: {
    user_slug: {
      type: String,
      required: true,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select-player'],
  setup(props, { emit }) {
    const playerQuery = ref('')
    const loadingPl = ref(true)
    const playerSelected = ref(false)

    // NEW FOR PLAYER NAMES FROM STORE
    const store = useStore() // Get the Vuex store instance
    const loadingMyPlayers = ref(true)
    const playerSearchList = ref([])
    watch(
      () => store.state.myPlayers.length,
      (newLength) => {
        loadingMyPlayers.value = newLength === 0
        playerSearchList.value = store.state.myPlayers.map((player) => player.playerId)
        loadingPl.value = false
      },
      { immediate: true } // Runs on mount and on changes
    )
    loadingMyPlayers.value = store.state.myPlayers.length === 0

    // Filter players based on query
    const playersFiltered = computed(() => {
      if (!playerQuery.value) return []
      const slug = slugify(playerQuery.value, {
        replacement: '-',
        remove: /[$*_+~.()'"!\-:@]/g,
        lower: true,
      })
      nextTick()
      return playerSearchList.value.filter((p) => p.includes(slug))
    })

    // Emit selected player name and reset query
    const handleSelectPlayer = (player) => {
      emit('select-player', player)
      playerQuery.value = player
      playerSelected.value = true
    }
    const inputChanged = (player) => {
      if (playerSelected.value) {
        playerSelected.value = false
      }
    }

    return {
      playerQuery,
      playersFiltered,
      handleSelectPlayer,
      loadingPl,
      playerSelected,
      inputChanged,
    }
  },
}
</script>
 