export function favoriteLocationsReady(store) {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();
      const maxWaitTime = 10000; // 10 seconds in milliseconds
      const checkInterval = 300; // 0.3 seconds in milliseconds
  
      const checkLoaded = () => {
        if (store.state.myLocationsRawLoaded && store.state.myLocations.length) {
          resolve(); // Resolve the promise if myLocationsRawLoaded is true
        } else if (Date.now() - startTime > maxWaitTime) {
          reject(new Error('Timeout waiting for myLocationsRawLoaded to be true')); // Throw error after 10 seconds
        } else {
          setTimeout(checkLoaded, checkInterval); // Wait 0.3 seconds and check again
        }
      };
  
      checkLoaded(); // Start the initial check
    });
  }