<template>
  <button class="button-large" @click="handleSignin">{{ t('launchApp') }}</button>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default {
  name: 'AuthOption',
  setup() {
    const router = useRouter()
    const { t } = useI18n()

    const handleSignin = () => {
      router.push('/signin')
    }
    const handleRegister = () => {
      router.push('/register')
    }

    return {
      t,
      handleSignin,
      handleRegister,
    }
  },
}
</script>