import { ref, watch } from 'vue';

export function useModal() {
  const isModalVisible = ref(false);
  const modalMessage = ref('');
  const modalHeading = ref('');

  const showModal = (message, heading = '') => {
    modalMessage.value = message;
    modalHeading.value = heading;
    isModalVisible.value = true;

    return new Promise((resolve) => {
      const onModalClose = () => {
        isModalVisible.value = false;
        resolve(); // Resolve the promise when the modal is closed
      };

      const stopWatching = watch(
        () => isModalVisible.value,
        (newVal) => {
          if (!newVal) {
            stopWatching(); // Stop watching when the modal is hidden
            onModalClose();
          }
        }
      );
    });
  };

  return {
    isModalVisible,
    modalMessage,
    modalHeading,
    showModal,
  };
}
