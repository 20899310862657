import { ref } from 'vue'
import { db } from "@/firebase/config";
import { doc, getDoc } from "firebase/firestore";
const playerRec = ref([])

const getPlayer = async (uId) => {
    // console.log('US:', uId)
    if (uId) {
        const docRef = doc(db, "players", uId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            playerRec.value = docSnap.data()
        } else {
            // doc.data() will be undefined in this case
            console.log("Player not found (12)");
        }
    }
    return playerRec
}
export default getPlayer
