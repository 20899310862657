<template>
  <div v-if="documents" @submit.prevent class="outer-wrapper">
    <div class="normal-subheading">
      <strong>Conversation with:</strong>
      @{{ msgTo }}
    </div>
    <textarea class="dynamic-textarea" inputmode="text" autocomplete="off" placeholder="Type message then tap send." v-model="message" @input="adjustTextareaHeight" @keypress.enter.prevent="handleSubmit" ref="messageInput"></textarea>
    <!-- send button -->
    <button v-if="!sendingMessage" @click="handleSubmit" class="button-large-small-text">
      Send
      <i class="fa-solid fa-paper-plane-top"></i>
    </button>
    <button v-else class="button-large-small-text-disabled">
      Sending
      <i class="fa-solid fa-paper-plane-top"></i>
    </button>

    <div v-if="visibleThreads.length" class="outer-wrapper">
      <div v-for="doc in visibleThreads" :key="doc.id" class="message-wrapper">
        <div :class="doc.fromId === msgToId ? 'their-message' : 'my-message'">
          <div class="message-detail-timestamp">{{ doc.created }}</div>
          <div class="text-message-content">
            <span style="font-weight:500;">{{ doc.from }}:</span>
            {{ doc.message }}
          </div>
          <div v-if="doc.type == 'teamReq' && doc.fromId === msgToId" class="divButton" style="max-width: 120px;" @click="acceptTeam(doc.eventId, doc.fromId, doc.toId)">Accept</div>
          <div v-else-if="doc.type == 'adminReq' && doc.fromId === msgToId" class="divButton" style="max-width: 120px;" @click="acceptAdmin(doc.locationId, doc.toId)">Accept</div>
        </div>
      </div>
    </div>
    <div class="normal-spacer"></div>
    <button v-if="visibleThreads.length && hasMore" @click="loadMoreThreads" class="button-large-small-text">Show More</button>

  </div>
  <div class="normal-spacer"></div>
</template>

<script>
import getMessages from '@/composables/getMessages'
import { formatDistanceToNow } from 'date-fns'
import { computed, ref, onMounted } from 'vue'
import { db, functions } from '@/firebase/config'
import { collection, addDoc, doc, getDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore'
import slugify from 'slugify'
import updateMessagesReadAll from '@/composables/updateMessagesReadAll'
import { httpsCallable } from 'firebase/functions'

export default {
  name: 'show-message-thread',
  props: {
    user_slug: {
      type: String,
      required: true,
    },
    pName: {
      type: String,
      required: true,
    },
    user_name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    httpsCallable(functions, 'documentUpdates-addDocument')({ id: 'wakeUp' })
    httpsCallable(functions, 'documentUpdates-setDocument')({ id: 'wakeUp' })
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always scroll to top on load

    const threadsPerPage = 10 // Number of threads to display per page
    const currentPage = ref(1) // Tracks the current page
    const visibleThreads = computed(() => {
      return documentsFormatted.value.slice(0, currentPage.value * threadsPerPage)
    })
    // Check if there are more threads to display
    const hasMore = computed(() => {
      return documentsFormatted.value.length > visibleThreads.value.length
    })

    // Function to load more threads by increasing the page count
    const loadMoreThreads = () => {
      currentPage.value += 1
    }

    const msgTo = props.pName
    let msgToId = slugify(props.pName, {
      replacement: '-',
      remove: /[$*_+~.()'"!\-:@]/g,
      lower: true,
    })
    const sendingMessage = ref(false)
    const user_name = ref(props.user_name)
    // need to get the other player's photo here.
    const playerPhotoURL = ref('https://firebasestorage.googleapis.com/v0/b/pickled-sandbox.appspot.com/o/images%2FPurplePickledLogo-Thumb.png?alt=media&token=4cff4290-084b-4cce-b513-1ab6e7a6f832')
    const getOpp = async (msgToId) => {
      const docRef = doc(db, 'players', msgToId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        playerPhotoURL.value = docSnap.data().photoURL
      }
    }
    getOpp(msgToId)
    // get my photo URL
    const myPhotoURL = ref('https://firebasestorage.googleapis.com/v0/b/pickled-sandbox.appspot.com/o/images%2FPurplePickledLogo-Thumb.png?alt=media&token=4cff4290-084b-4cce-b513-1ab6e7a6f832')
    const getMy = async (us) => {
      const docRef = doc(db, 'players', us)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        myPhotoURL.value = docSnap.data().photoURL
      }
    }
    getMy(props.user_slug)

    // get last 99 texts between parties
    const { error, documents } = getMessages(props.user_slug, msgToId)

    const documentsFormatted = computed(() => {
      if (documents.value) {
        return documents.value.map((doc) => {
          let time = formatDistanceToNow(doc.created.toDate())
          return { ...doc, created: time }
        })
      }
    })
    // update as read
    updateMessagesReadAll(msgToId + props.user_slug)

    // message send
    let toId = slugify(props.pName, {
      replacement: '-',
      remove: /[$*_+~.()'"!\-:@]/g,
      lower: true,
    })

    const handleSubmit = async () => {
      if (message.value) {
        sendingMessage.value = true
        let messageDoc = {
          created: serverTimestamp(),
          type: 'txt',
          fromId: props.user_slug,
          from: user_name.value,
          toId: toId,
          to: props.pName,
          channel: props.user_slug + toId,
          message: message.value,
          read: false,
        }
        // add to messages
        const addMessage = httpsCallable(functions, 'documentUpdates-addDocument')
        try {
          await addMessage({
            collection: 'messages',
            fields: messageDoc,
          })
        } catch (error) {
          sendingMessage.value = false
          console.error(`Error adding message in cloud function documentUpdates-addDocument`, error)
        }
        const editMessageLast = httpsCallable(functions, 'documentUpdates-setDocument')
        try {
          await editMessageLast({
            collection: 'messageLast',
            id: props.user_slug + toId,
            fields: {
              created: serverTimestamp(),
              type: 'txt',
              fromId: props.user_slug,
              from: user_name.value,
              toId: toId,
              to: props.pName,
              channel: props.user_slug + toId,
              message: '(' + user_name.value + '):' + message.value,
              read: false,
              channelPhotoURL: myPhotoURL.value ? myPhotoURL.value : '',
            },
          })
        } catch (error) {
          sendingMessage.value = false
          console.error(`Error updating messageLast in cloud function documentUpdates-setDocument`, error)
        }

        try {
          await editMessageLast({
            collection: 'messageLast',
            id: toId + props.user_slug,
            fields: {
              created: serverTimestamp(),
              type: 'txt',
              fromId: toId,
              from: props.pName,
              toId: props.user_slug,
              to: user_name.value,
              channel: toId + props.user_slug,
              message: '(Me):' + message.value,
              read: true, // becasue I sent this
              channelPhotoURL: playerPhotoURL.value ? playerPhotoURL.value : '',
            },
          })
        } catch (error) {
          sendingMessage.value = false
          console.error(`Error updating messageLast second channel in cloud function documentUpdates-setDocument`, error)
        }
        message.value = ''
      }
      message.value = ''
      sendingMessage.value = false
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always scroll to top on load

    const acceptTeam = async (eventId, p1, p2) => {
      // Ensure p1 is alphabetically before p2
      if (p1 > p2) {
        ;[p1, p2] = [p2, p1] // Swap p1 and p2
      }

      // Query to check if a team already exists with the same eventId, p1, and p2
      const teamQuery = query(collection(db, 'eventTeams'), where('eventId', '==', eventId), where('team', 'array-contains-any', [p1, p2]), where('deleted', '==', false))

      const querySnapshot = await getDocs(teamQuery)
      if (!querySnapshot.empty) {
        alert('Team already accepted')
        return
      }

      // If the team does not exist, create a new document
      let teamDoc = {
        created: serverTimestamp(),
        deleted: false,
        eventId: eventId,
        p1: p1,
        p2: p2,
        team: [p1, p2],
      }

      await addDoc(collection(db, 'eventTeams'), teamDoc)
      alert('Team formed!')
    }

    const acceptAdmin = async (locationId, pId) => {
      // Query to check if already an admin
      const adminQuery = query(collection(db, 'locationsAdmin'), where('locationId', '==', locationId), where('playerId', '==', pId))

      const queryAdminSnapshot = await getDocs(adminQuery)
      if (!queryAdminSnapshot.empty) {
        alert(`${pId} is already an admin.`)
        return
      } else {
        if (confirm(`Do you wish to accept admin privileges for ${locationId}?`)) {
          let adminDoc = {
            created: serverTimestamp(),
            locationId: locationId,
            playerId: pId,
          }
          await addDoc(collection(db, 'locationsAdmin'), adminDoc)
          alert(`Congratulations!  You are now an admin at ${locationId}!`)
        }
      }
    }

    onMounted(() => {
      adjustTextareaHeight()
    })
    const messageInput = ref(null)
    const message = ref('')
    const adjustTextareaHeight = () => {
      const textarea = messageInput.value
      if (textarea) {
        // textarea.style.height = 'auto'; // Reset to auto to recalculate height
        textarea.style.maxHeight = `${Math.min(textarea.scrollHeight, 120)}px` // Limit height to 120px max
      }
    }

    return {
      error,
      documents,
      documentsFormatted,
      playerPhotoURL,
      message,
      handleSubmit,
      user_name,
      msgTo,
      msgToId,
      acceptTeam,
      acceptAdmin,
      sendingMessage,
      adjustTextareaHeight,
      messageInput,
      visibleThreads,
      hasMore,
      loadMoreThreads,
    }
  },
}
</script>

<style scoped>
.dynamic-textarea {
  width: 87.5%;
  max-height: 40px;
  overflow-y: auto;
  resize: none; /* Prevent manual resizing */
  padding: 0 var(--padding-tiny) 0 var(--padding-tiny); /* Remove padding for height consistency */
  border: 1px solid var(--text-color);
  border-radius: var(--radius-regular);
  font-size: var(--font-size-subheading);
  line-height: 1.5;
  box-sizing: content-box; /* Exclude padding from height calculation */
  margin-bottom: var(--margin-tiny);
}

.message-wrapper {
  width: 90%;
}

.my-message {
  margin-left: auto;
  margin-right: 0;
  text-align: left;
  width: 80%;
  margin-top: var(--margin-tiny);
  border-radius: var(--radius-regular);
  background-color: var(--text-message-background-me);
  padding: 4px;
}

.their-message {
  margin-left: 0;
  margin-right: auto;
  text-align: left;
  width: 80%;
  margin-top: var(--margin-tiny);
  border-radius: var(--radius-regular);
  background-color: var(--text-message-background-them);
  padding: 4px;
}

.text-message-content {
  font-size: var(--font-size-subheading);
  word-wrap: break-word;
  color: var(--input-text-color);
}

.message-detail-timestamp {
  font-size: var(--font-size-regular);
  color: var(--input-text-color);
  font-weight: 500;
  text-align: right;
  padding-right: 4px;
}
</style>
