<template>
  <PopupCelebration v-if="showCelebration" :emoji="currentEmoji" :isVisible="showCelebration" :heading-text="$t('congratulations')" :subheading-text="$t('diamond-register')" @hide="showCelebration = false" />
  <div class="logo-scale">
    <img src="@/assets/BaseLogo-ThumbCircle.png" alt="Pickled.App" />
  </div>
  <div class="normal-heading">{{ $t('welcome') }}</div>
  <p class="large-p">{{ $t('thrilled') }}</p>
  <p class="large-p">{{ $t('ballsIn') }}</p>
  <div class="normal-spacer"></div>
  <button class="button-large-small-text" @click="handleProfile">{{ $t('complete-profile') }}</button>
  <button class="button-large-small-text" @click="handleOk">{{ $t('show-events') }}</button>
  <div class="normal-spacer"></div>
</template>

<script>
import { useRouter } from 'vue-router'
import getUser from '@/composables/getUser'
import { functions } from '@/firebase/config'
import { httpsCallable } from 'firebase/functions'
import { useI18n } from 'vue-i18n'
import PopupCelebration from '@/components/PopupCelebration.vue'
import { ref } from 'vue'

export default {
  name: 'Welcome',
  components: {
    PopupCelebration,
  },
  setup() {
    const { t } = useI18n()
    const showCelebration = ref(false)
    const currentEmoji = ref('')
    const router = useRouter()
    const { user_slug } = getUser()
    const updateSetupComplete = async () => {
      if (user_slug.value) {
        try {
          console.log('Have slug, will travel')
          currentEmoji.value = '💎'
          showCelebration.value = true
          const updatePlayer = httpsCallable(functions, 'players-updatePlayer')
          await updatePlayer({ playerId: user_slug.value, fields: { setupComplete: true } })
          // Award diamond in players-playerReward cloud function
          try {
            const rewardPlayer = httpsCallable(functions, 'players-playerReward')
            await rewardPlayer({ diamondId: 'registered' })
            console.log('Diamond awarded successfully.')
          } catch (rewardError) {
            console.error('Error awarding diamond in cloud function players-playerReward', rewardError)
          }
        } catch (error) {
          console.error(`Error updating setupComplete in cloud function players-updatePlayer`, error)
        }
      } else {
        console.log("Haven't any slug")
      }
    }

    updateSetupComplete()
    // Modify this to use onMounted to get slug from Vuex then call updateSetupComplete after the await.
    const handleOk = () => {
      router.push({ name: 'home' })
    }
    
    const handleProfile = () => {
      router.push({ name: 'player' })
    }

    return {
      handleOk,
      handleProfile,
      t,
      currentEmoji,
      showCelebration,
    }
  },
}
</script>

<style scoped>
.logo-scale {
  display: flex;
  padding-top: 8px;
}
.logo-scale img {
  margin: auto;
  width: 33%;
}
</style>