import { db } from "@/firebase/config";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { useStore } from "vuex"; // Import useStore to access Vuex store

export default function usePlayersForLocation(playersLocation, playerNames, playerCount, playerTotal) {
  const store = useStore(); // Initialize Vuex store access

  const getPlayersForLocation = async () => {
    let locResults = [];
    let playersForLocation = [];
    let counter1 = 0;
    playerCount.value = 0;
    playerTotal.value = 0;

    // First query to get player IDs from playerLocations (unchanged)
    const q = query(collection(db, "playerLocations"), where("locationId", "==", playersLocation.value), orderBy("playerId"));
    // console.log(`🏠 getting playerLocations for ${playersLocation.value}`);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((playerLoc) => {
      locResults.push({ ...playerLoc.data(), id: playerLoc.id });
      playersForLocation.push(playerLoc.data().playerId);
      counter1++;
    });
    playerTotal.value = counter1;

    // Replace second Firestore query with lookup from myPlayers
    const myPlayers = store.state.myPlayers; // Access myPlayers from Vuex store
    for (let i = 0; i < playersForLocation.length; i++) {
      const playerId = playersForLocation[i];
      const player = myPlayers.find((p) => p.playerId === playerId);
      if (player) {
        // Push player data in the same format as the original
        playerNames.value.push({ ...player, id: playerId });
        playerCount.value++;
      } else {
        console.warn(`Player with ID ${playerId} not found in myPlayers`);
      }
    }
  };

  return { getPlayersForLocation };
}