import { ref } from "vue";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "@/firebase/config";

export default function useToggleFriend() {
    const error_ToggleFriend = ref(null); // Track errors

    const toggleFriend = async (user_slug, pId, addFriend) => {
        if (user_slug === pId && addFriend) {
            alert("Sorry, but you can't friend yourself 😜.")
            return
        }

        try {
            await setDoc(
                doc(db, "playerFriends", user_slug + pId),
                {
                    playerId: user_slug,
                    created: serverTimestamp(),
                    friend: addFriend,
                    friendId: pId,
                },
                { merge: true }
            );

            // if (addFriend) {
            //     alert(`${pId} added to your friends.`)
            // } else {
            //     alert(`${pId} removed from friends.`)
            // }
        } catch (error) {
            console.error("Error toggling friends:", error);
            error_ToggleFriend.value = error.message;
        }
    };

    return {
        toggleFriend,
        error_ToggleFriend,
    };
}
