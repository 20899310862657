import { db, functions } from "@/firebase/config";
import { doc, setDoc, serverTimestamp, collection, query, where, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

httpsCallable(functions, 'players-updatePlayer')({ playerId: 'wakeUp' })

const addPlayerLocation = async (pId, lId, setHome = false, bypassLimit = false) => {

    // new bandaid as of 12/27/24 to always set home in case they clicked add favorite during setup, shouldn't hurt anything bc home concept is being deprecated
    setHome = true
    if (pId && lId) {
        // new query playerLocations first to see if this player has >= 10 locations already and if so, alert and return

        try {
            // Query the playerLocations collection for records matching the playerId
            const playerLocationsRef = collection(db, "playerLocations");
            const playerQuery = query(playerLocationsRef, where("playerId", "==", pId), where("removed", "==", false));
            const querySnapshot = await getDocs(playerQuery);

            // ✅ Check if the location already exists for the player
            const locationExists = querySnapshot.docs.some(doc => doc.data().locationId === lId);
            if (locationExists) {
                console.log('Location already exists');
                return;
            }
            // Check if the player has 15 or more favorite locations
            let maxLocLimit = bypassLimit ? 99 : 20
            const hasReachedLimit = querySnapshot.size >= maxLocLimit;
            if (hasReachedLimit) {
                alert(`There is a limit of ${maxLocLimit} favorite venues per player.`)
                return
            } else {
                try {
                    await setDoc(doc(db, "playerLocations", pId + lId), {
                        created: serverTimestamp(),
                        locationId: lId,
                        playerId: pId,
                        removed: false,
                    });
                    // alert('Venue added to your favorites!')
                } catch (error) {
                    throw error;  // rethrowing the error so the calling function is aware an error occurred
                }
            }
        } catch (error) {
            console.error(`Error adding player location for Player ID: ${pId}, Location ID: ${lId}`, error);
            console.log("Error in adding location")
            throw error;  // rethrowing the error so the calling function is aware an error occurred
        }


        if (setHome) {
            // console.log('updating home location')
            try {
                const updatePlayer = httpsCallable(functions, 'players-updatePlayer');
                await updatePlayer({ playerId: pId, fields: { "homeLocation": lId } });
            } catch (error) {
                console.error(`Error setting home location for Player ID: ${pId}, Location ID: ${lId} in cloud function players-updatePlayer`, error);
                throw error;  // rethrowing the error so the calling function is aware an error occurred
            }
        }
    }
}

const remPlayerLocation = async (pId, lId) => {
    if (pId && lId) {
        try {
            await setDoc(doc(db, "playerLocations", pId + lId), {
                removed: serverTimestamp(),
                locationId: lId,
                playerId: pId,
                removed: true,
            });
        } catch (error) {
            console.error(`Error adding player location for Player ID: ${pId}, Location ID: ${lId}`, error);
            console.log("Error in adding location")
            throw error;  // rethrowing the error so the calling function is aware an error occurred
        }

    }

}

export default addPlayerLocation;
// for backward compatibility
export { remPlayerLocation };