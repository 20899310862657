<template>
  <Smash :isVisible="loadingData" labelText="Initializing player" labelSubtext="almost done" />
  <div v-if="!loadingData" class="outer-wrapper">
    <i class="fa-solid fa-address-card info-icon-large"></i>
    <div class="large-p" style="color: var(--error-text-color);">
      <strong>Once selected, your player name cannot be changed. Choose wisely!</strong>
    </div>
   
    <div class="normal-heading">Choose Player Name</div>

    <div class="input-block-signin">
      <i class="fa-solid fa-user-large"></i>
      <input type="text" inputmode="text" autocomplete="off" name="player" maxlength="12" :placeholder="$t('choosePlayerName')" v-model="v$.reqPlayerName.$model" />
    </div>

    <div class="input-block-err" v-if="v$.reqPlayerName.$error">
      <span v-for="error of v$.reqPlayerName.$errors" :key="error.$uid">{{ error.$message }}</span>
    </div>

    <!-- Check name lifecycle logic -->
    <div class="outer-wrapper" v-if="disableCheckButton">
      <div class="button-large-small-text-disabled">{{ buttonLabel }}</div>
    </div>
    <div v-else-if="wasNameChecked" class="outer-wrapper">
      <div v-if="nameAvailable" class="normal-heading">{{ reqPlayerName }} is available!</div>
      <div v-if="nameAvailable" class="button-large-small-text" @click="setPlayerName">Set Player Name</div>
      <div v-else class="normal-heading">Name not available, try again.</div>
    </div>

    <div v-else class="button-large-small-text" @click="playerAvailable">Check Availability</div>

    <div class="large-p">Choose a unique player name of up to 12 characters to represent your public persona. This name will be displayed on your profile and for every event you join.</div>
    <button class="button-large-small-text" @click="handleCancel">Cancel</button>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import slugify from 'slugify'
import { required, maxLength, minLength, helpers } from '@vuelidate/validators'
import { httpsCallable } from 'firebase/functions'
import { db, functions, auth } from '@/firebase/config'
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore'
import { updateProfile, signOut } from 'firebase/auth'
// import useUpdatePlayer from '@/composables/updatePlayer'
import getUser from '@/composables/getUser'
import addPlayerLocation from '@/composables/addPlayerLocation'
// new for spinner
import Smash from '@/components/Smash.vue'

export default {
  name: 'PlayerNameSelect',
  emits: ['playerNameSet'],
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  components: {
    Smash,
  },
  setup(props, { emit }) {
    const buttonLabel = ref('Check Availability')
    const loadingData = ref(false)
    const router = useRouter()
    const { t } = useI18n()
    const handleLanding = () => {
      router.push('/landing')
    }
    const continueSignIn = () => {
      router.push({ name: 'signIn', query: { justRegistered: true } })
    }

    const handleCancel = () => {
      signOut(auth)
      router.push({ name: 'landing' })
    }
    const reqPlayerName = ref('')
    const v$ = useVuelidate()
    const disableCheckButton = ref(true)
    const nameAvailable = ref('')
    const wasNameChecked = ref(false)
    // new background wakeup calls to all pending cloud functions
    httpsCallable(functions, 'validations-checkPlayer')({ id: 'wakeUp' })
    httpsCallable(functions, 'documentUpdates-updateDocument')({ id: 'wakeUp' })
    httpsCallable(functions, 'players-updatePlayer')({ playerId: 'wakeUp' })
    httpsCallable(functions, 'players-updatePlayerPrivate')({ playerId: 'wakeUp' })
    // end new wakup stuff

    const playerAvailable = async () => {
      disableCheckButton.value = true
      wasNameChecked.value = true
      buttonLabel.value = 'Checking, please wait...'
      if (reqPlayerName.value > '') {
        let slug = null
        slug = slugify(reqPlayerName.value.trim(), {
          replacement: '-',
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        })
        // query cloud function to see if player name already exists
        const nameOk = httpsCallable(functions, 'validations-checkPlayer')
        try {
          const result = await nameOk({ id: slug })
          disableCheckButton.value = false
          nameAvailable.value = !result.data.playerExists

          return !result.data.playerExists
        } catch (error) {
          console.error(`Error validating player in cloud function validations-checkPlayer`, error)
        }
      } else {
        console.log('no name input')
        nameAvailable.value = false
        disableCheckButton.value = false
        return true
      }
    }

    const setPlayerName = async () => {
      loadingData.value = true
      disableCheckButton.value = true
      buttonLabel.value = 'Updating player...'
      try {
        const playerStageCollection = collection(db, 'playerStage')
        //going to need to pull uid in from parent
        const q = query(playerStageCollection, where('userId', '==', props.uid))
        const querySnapshot = await getDocs(q)
        // If a document is found, update the playerName field
        if (!querySnapshot.empty) {
          const docId = querySnapshot.docs[0].id // Assuming there's only one document per player
          const updateplayerStage = httpsCallable(functions, 'documentUpdates-updateDocument')
          try {
            await updateplayerStage({ collection: 'playerStage', fields: { playerName: reqPlayerName.value }, id: docId })
          } catch (error) {
            console.error(`Error updating playerName in cloud function documentUpdates-updateDocument`, error)
          }

          // const playerDocRef = doc(playerStageCollection, docId);
          // await updateDoc(playerDocRef, {
          //     playerName: reqPlayerName.value
          // });
          console.log('Player name updated successfully')
          // Update the authentication displayName to the new playerName
          try {
            // deprecated in 2.0
            // await updateProfile(user.value, {
            //   displayName: reqPlayerName.value,
            // })
            // console.log('Auth display name updated successfully')
            // now update players collection
            let slug = slugify(reqPlayerName.value, {
              replacement: '-',
              remove: /[$*_+~.()'"!\-:@]/g,
              lower: true,
            })

            // Get all fields from the document, excluding the id
            let playerData = { ...querySnapshot.docs[0].data() }
            playerData.playerName = reqPlayerName.value
            delete playerData.id // remove the id field

            const updatePlayer = httpsCallable(functions, 'players-updatePlayer')
            const result = await updatePlayer({ playerId: slug, fields: playerData })

            const updatePlayerPrivate = httpsCallable(functions, 'players-updatePlayerPrivate')
            const resultPrivate = await updatePlayerPrivate({ playerId: slug, fields: { userId: playerData.userId } })

            if (result.data.success && resultPrivate.data.success) {
              console.log('player updated')
              // add homeLocation to favorites
              if (playerData.homeLocation) {
                // here
                loadingData.value = true
                await addPlayerLocation(slug, playerData.homeLocation)
                loadingData.value = false
              }
              emit('playerNameSet')
              // router.push({ name: "home" });
              // location.reload();
            } else {
              loadingData.value = false
              console.log('player NOT updated')
            }
          } catch (profileError) {
            loadingData.value = false
            console.error('Error updating auth display name:', profileError)
          }
        } else {
          loadingData.value = false
          console.log('No document found for the given user ID')
        }
      } catch (error) {
        loadingData.value = false
        console.error('Error updating player name:', error)
      }
      loadingData.value = false
      disableCheckButton.value = false
      buttonLabel.value = 'Done!'
    }

    watch(reqPlayerName, (newValue, oldValue) => {
      // If the player name was changed after a check
      if (wasNameChecked.value && newValue !== oldValue) {
        wasNameChecked.value = false
        nameAvailable.value = false
      }
      if (newValue && newValue.length >= 3) {
        disableCheckButton.value = false
      } else {
        disableCheckButton.value = true
      }
    })

    return {
      t,
      handleLanding,
      continueSignIn,
      reqPlayerName,
      v$,
      playerAvailable,
      disableCheckButton,
      nameAvailable,
      wasNameChecked,
      setPlayerName,
      buttonLabel,
      handleCancel,
      loadingData,
    }
  },
  validations() {
    return {
      reqPlayerName: {
        required: helpers.withMessage('Please input a player name.', required),
        maxLength: helpers.withMessage('12 characters max. ', maxLength(12)),
        minLength: helpers.withMessage('3 characters min. ', minLength(3)),
        regex: helpers.withMessage('Only letters, numbers, and the symbols "_ -" are allowed.', helpers.regex(/^[a-zA-Z0-9_\-]+$/)),
      },
    }
  },
}
</script>
