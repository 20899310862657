<template>
  <!-- REMOVE FOR PROD -->
  <!-- <div id="width-indicator"></div> -->
  <!-- END REMOVE FOR PROD -->
  <PreferenceSelector :initialLanguage="language" @language-changed="changeLanguage" />
  <LogoHeading />
  <div class="normal-spacer"></div>
  <AuthOption />
  <HelpLinks />
  <Slogan />
  <FeatureList />
  <div class="normal-spacer"></div>
  <TermsPrivacy />
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import Cookies from 'js-cookie'
import PreferenceSelector from '@/components/PreferenceSelector.vue'
import LogoHeading from '@/components/LogoHeading.vue'
import FeatureList from '@/components/FeatureList.vue'
import HelpLinks from '@/components/HelpLinks.vue'
import AuthOption from '@/components/AuthOption.vue'
import TermsPrivacy from '@/components/TermsPrivacy.vue'
import Slogan from '@/components/Slogan.vue'

export default {
  components: {
    PreferenceSelector,
    LogoHeading,
    FeatureList,
    HelpLinks,
    AuthOption,
    TermsPrivacy,
    Slogan,
  },
  setup() {
    // document.body.scrollTop = document.documentElement.scrollTop = 0 // always scroll to top on load
    const { locale } = useI18n()
    const language = ref(locale.value)

    const changeLanguage = (lang) => {
      locale.value = lang
      Cookies.set('language', lang, { expires: 365 }) // store the language for 365 days
    }

    const loadLanguagePreference = () => {
      const savedLanguage = Cookies.get('language')
      if (savedLanguage) {
        locale.value = savedLanguage
      }
    }

    const updateWidth = () => {
      const widthIndicator = document.getElementById('width-indicator')
      if (widthIndicator) {
        const width = window.innerWidth
        widthIndicator.textContent = `Width: ${width}px`
      }
    }

    onMounted(() => {
      loadLanguagePreference()

      // Initial call to set the correct value when the page loads
      updateWidth()

      // Update whenever the window is resized
      window.addEventListener('resize', updateWidth)
    })

    onBeforeUnmount(() => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('resize', updateWidth)
    })

    return {
      changeLanguage,
      loadLanguagePreference,
      language,
    }
  },
}
</script>

<style scoped>
#width-indicator {
  position: fixed;
  top: 10px; /* Distance from the top of the screen */
  left: 50%;
  transform: translateX(-50%); /* Center it horizontally */
  background: rgba(0, 0, 0, 0.7); /* Slightly transparent black background */
  color: white; /* Text color */
  padding: 5px 10px; /* Padding for better readability */
  font-size: 16px; /* Font size to make it visible */
  border-radius: 5px; /* Rounded corners for better aesthetics */
  z-index: 9999; /* Ensure it's on top of other elements */
  pointer-events: none; /* Make sure the indicator is not clickable */
}
</style>
