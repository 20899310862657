<template>
  <router-link :to="route" class="menu-button" :aria-label="label">
    <div class="button-content">
      <img v-if="imgSrc" :src="imgSrc" alt="Menu Image" class="menu-img" />
      <i v-else :class="icon" class="menu-icon"></i>
      <span class="menu-label">{{ label }} <span v-if="notice">({{ notice }} new)</span></span>
    </div>
  </router-link>
</template>
  
  <script>
export default {
  name: 'MenuButton',
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    notice: {
      type: Number,
      required: false,
    },
    imgSrc: {
      type: String,
      default: null,
    },
  },
}
</script>
  
  <style scoped>
.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-color);
  color: var(--button-text-color);
  text-decoration: none;
  border-radius: var(--radius-regular);
  width: 100%;
  height: var(--menu-button-height);
  font-size: var(--font-size-regular);
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease;
}

.menu-button:hover {
  background-color: var(--button-hover-color);
}

.button-content {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;
}

.menu-icon {
  font-size: var(--font-size-menuIcon);
  margin-bottom: var(--padding-tiny); /* Adds space between icon and label */
  color: var(--button-text-color);
}

.menu-label {
  font-size: var(--font-size-regular); /* Adjust font size for better readability */
  font-weight: 500; /* Slightly bolder text */
}

.menu-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: var(--padding-small);
}
</style>
  