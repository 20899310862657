<template>
  <section class="help-link-grid-container">
    <button class="button-large-small-text" @click="openLink('https://getpickled.app')" aria-label="GetPickled Website">
      <i class="fa-solid fa-globe"></i>
      Website
    </button>
    <button class="button-large-small-text" @click="openLink('https://www.facebook.com/groups/1429905987777664')" aria-label="Facebook Group">
      <i class="fa-brands fa-facebook"></i>
      {{ t("facebookGroup") }}
    </button>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'HelpLinks',
  setup() {
    const { t } = useI18n()

    const openLink = (url) => {
      window.open(url, '_blank', 'noopener,noreferrer')
    }

    return {
      t,
      openLink,
    }
  },
}
</script>

<style scoped>
.help-link-grid-container {
  display: flex;
  justify-content: space-between;
  gap: var(--grid-gap);
  /* padding: 20px; */
  width: var(--width-default);
  /* margin: 0 auto; */
}

</style>
