import { db, functions } from '@/firebase/config'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from "firebase/functions";

const updatePlayerPhoto = async (p, durl) => { 
    const updatePlayer = httpsCallable(functions, 'players-updatePlayer');
    try {
    await updatePlayer({playerId: p, fields: { "photoURL": durl + '&dnpa=' + Date.now()}});
    } catch (error) {
        console.error(`Error updating photoURL in cloud function players-updatePlayer`, error);

    }
    return
}
export default updatePlayerPhoto