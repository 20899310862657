<template>
  <ImageUpload :targetId="targetId" :target="target" :storagePath="storagePath" :cropWidth="cropWidth" :cropHeight="cropHeight" :cropRatio="cropRatio" />
</template>

<script>
import ImageUpload from '@/components/ImageUpload.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'Upload',
  components: { ImageUpload },
  props: ['targetId'],
  setup() {
    const route = useRoute()

    // Extract query parameters
    const target = route.query.target || 'default'
    const storagePath = route.query.storagePath || ''
    const cropWidth = Number(route.query.cropWidth) || 340
    const cropHeight = Number(route.query.cropHeight) || 400
    const cropRatio = Number(route.query.cropRatio) || cropWidth / cropHeight

    return {
      target,
      storagePath,
      cropWidth,
      cropHeight,
      cropRatio,
    }
  },
}
</script>
