// src/store.js
// console.log('VX: Store initializing');

import { createStore } from 'vuex';
import { auth, db } from '@/firebase/config';
import { query, where, collection, getDocs, doc, onSnapshot, getDoc, documentId } from "firebase/firestore";
import router from "@/router";
import { get, set } from 'idb-keyval';


let pollInterval = null;

export default createStore({
  state: {
    user: null,
    isAppSleeping: false,
    curPlayerData: null,
    playerListenerUnsub: null,
    playerListenerStatus: 'idle',
    userOnboardingStatus: null,
    version: null,
    versionListenerUnsub: null,
    currentLocLat: null,
    currentLocLon: null,
    lastLat: null,
    lastLon: null,
    myLocationsRaw: [],
    myLocationsRawLoaded: false,
    myLocationsListenerUnsub: null,
    myPlayersRaw: [],
    myPlayersRawLoaded: false,
    myPlayersRawListenerUnsubs: [],
    myPlayers: [],
    myPlayersRawMap: {},
    myLocationsAdminRaw: [],
    myLocationsAdminRawLoaded: false,
    myLocationsAdminListenerUnsub: null,
    myLocations: [],
    myLocationsRawMap: {},
    myFriendsRaw: [],
    myFriendsRawLoaded: false,
    myFriendsListenerUnsub: null,
    myFavoritesRaw: [],
    myFavoritesRawLoaded: false,
    myFavoritesListenerUnsub: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
    setAppSleeping(state, isSleeping) {
      state.isAppSleeping = isSleeping;
    },
    setPlayerData(state, playerData) {
      state.curPlayerData = playerData;
    },
    clearPlayerData(state) {
      state.curPlayerData = null;
    },
    setPlayerListenerUnsub(state, unsub) {
      state.playerListenerUnsub = unsub;
    },
    setPlayerListenerStatus(state, status) {
      state.playerListenerStatus = status;
    },
    setUserOnboardingStatus(state, status) {
      state.userOnboardingStatus = status;
    },
    setVersion(state, version) {
      state.version = version;
      console.log(`Version set to: ${version}`);
    },
    setVersionListenerUnsub(state, unsub) {
      state.versionListenerUnsub = unsub;
    },
    setCurrentLocation(state, { lat, lon }) {
      state.currentLocLat = lat;
      state.currentLocLon = lon;
    },
    clearCurrentLocation(state) {
      state.currentLocLat = 0;
      state.currentLocLon = 0;
    },
    setLastLocation(state, { lastLat, lastLon }) {
      state.lastLat = lastLat;
      state.lastLon = lastLon;
    },
    clearLastLocation(state) {
      state.lastLat = null;
      state.lastLon = null;
    },
    setMyLocationsRaw(state, locations) {
      state.myLocationsRaw = locations;
    },
    setMyLocationsRawLoaded(state, loaded) {
      state.myLocationsRawLoaded = loaded;
    },
    setMyPlayersRaw(state, playersRaw) {
      state.myPlayersRaw = playersRaw;
    },
    setMyPlayersRawLoaded(state, value) {
      state.myPlayersRawLoaded = value;
    },
    setMyPlayers(state, players) {
      state.myPlayers = players;
    },
    setMyLocationsListenerUnsub(state, unsub) {
      state.myLocationsListenerUnsub = unsub;
    },
    setMyPlayersRawListenerUnsubs(state, unsubs) {
      state.myPlayersRawListenerUnsubs = unsubs;
    },
    updateMyPlayersRawMap(state, { locationId, playerIds }) {
      // Maintain a map internally for reference (optional)
      const existingPlayers = new Set(state.myPlayersRaw);
      playerIds.forEach((playerId) => existingPlayers.add(playerId));
      const updatedPlayersRaw = Array.from(existingPlayers);

      // console.log('🔄 Updated myPlayersRaw:', updatedPlayersRaw);
      state.myPlayersRaw = updatedPlayersRaw;

      // Save to IndexedDB
      set('myPlayersRaw', { data: updatedPlayersRaw })
        .then(() => console.log('💾'))
        .catch((err) => console.error('🔴 Error saving myPlayersRaw to IndexedDB:', err));

      // Mark as loaded after the first update
      if (!state.myPlayersRawLoaded) {
        state.myPlayersRawLoaded = true;
      }
    },
    setMyPlayersRawMap(state, map) {
      state.myPlayersRawMap = map;
      state.myPlayersRaw = [];
    },
    setMyLocationsAdminRaw(state, locations) {
      state.myLocationsAdminRaw = locations;
    },
    setMyLocationsAdminRawLoaded(state, loaded) {
      state.myLocationsAdminRawLoaded = loaded;
    },
    setMyLocationsAdminListenerUnsub(state, unsub) {
      state.myLocationsAdminListenerUnsub = unsub;
    },
    setMyLocations(state, locations) {
      state.myLocations = locations;
    },
    setMyLocationsRawMap(state, map) {
      state.myLocationsRawMap = map;
    },
    // new Friends
    setMyFriendsRaw(state, friends) {
      state.myFriendsRaw = friends;
    },
    setMyFriendsRawLoaded(state, loaded) {
      state.myFriendsRawLoaded = loaded;
    },
    setMyFriendsListenerUnsub(state, unsub) {
      state.myFriendsListenerUnsub = unsub;
    },
    // new Favorites
    setMyFavoritesRaw(state, favorites) {
      state.myFavoritesRaw = favorites;
    },
    setMyFavoritesRawLoaded(state, loaded) {
      state.myFavoritesRawLoaded = loaded;
    },
    setMyFavoritesListenerUnsub(state, unsub) {
      state.myFavoritesListenerUnsub = unsub;
    },
    updatePlayer(state, updatedPlayer) {
      const index = state.myPlayers.findIndex(p => p.playerId === updatedPlayer.playerId);
      if (index !== -1) {
        // Merge updated data into the existing player object
        state.myPlayers[index] = { ...state.myPlayers[index], ...updatedPlayer };
      } else {
        // If the player isn’t in the list yet, add it (optional, depending on your needs)
        state.myPlayers.push(updatedPlayer);
      }
    },
  },
  actions: {
    trackAuthState({ commit, dispatch }) {
      const checkAuthState = async () => {
        if (auth.currentUser) {
          // console.log('🔒 checkAuthState')
          try {
            await auth.currentUser.reload();
            const user = auth.currentUser;
            if (user) {
              commit('setUser', user);
            } else {
              dispatch('handleLogout');
            }
          } catch (error) {
            if (error.code === 'auth/user-token-expired') {
              dispatch('handleLogout');
            }
          }
        }
      };

      auth.onAuthStateChanged((user) => {
        // console.log('🔒🔓 onAuthStateChanged')
        if (user) {
          // console.log('🙋 User is authenticated:', user.uid);
          commit('setUser', user);
          if (!pollInterval) {
            pollInterval = setInterval(checkAuthState, 10000);
          }
          dispatch('startMyLocationsListener', user.uid);
          dispatch('startMyLocationsAdminListener', user.uid);
          dispatch('startMyFriendsListener', user.uid); // Add this line
          dispatch('startMyFavoritesListener', user.uid); // Add this line
        } else {
          // console.log('🔴 No user is authenticated');
          dispatch('handleLogout');
        }
      });
    },
    stopPlayerListener({ state, commit }) {
      if (state.playerListenerUnsub) {
        state.playerListenerUnsub();
        commit('setPlayerListenerUnsub', null);
        commit('clearPlayerData');
      }
    },
    handleLogout({ commit, dispatch }) {
      commit('clearUser');
      dispatch('stopPlayerListener');
      dispatch('stopMyLocationsListener');
      dispatch('stopMyLocationsAdminListener');
      dispatch('stopMyPlayersRawListener');
      dispatch('stopMyFriendsListener'); // Add this line
      dispatch('stopMyFavoritesListener'); // Add this line
      if (pollInterval) {
        clearInterval(pollInterval);
        pollInterval = null;
      }
      const currentPath = window.location.pathname;
      if (
        currentPath.startsWith('/register') ||
        currentPath === '/terms' ||
        currentPath === '/privacy'
      ) {
        router.push({ path: currentPath });
      } else {
        router.push({ name: 'home' });
      }
    },
    stopAuthPolling() {
      if (pollInterval) {
        clearInterval(pollInterval);
        pollInterval = null;
      }
    },
    async startPlayerListener({ commit }, userId) {
      try {
        commit('setPlayerListenerStatus', 'loading');
        const playersRef = collection(db, 'players');
        const q = query(playersRef, where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const docSnapshot = querySnapshot.docs[0];
          const playerDocRef = doc(db, 'players', docSnapshot.id);
          const playerPrivateDocRef = doc(db, 'playersPrivate', docSnapshot.id);

          const unsubscribePlayers = onSnapshot(
            playerDocRef,
            (doc) => {
              if (doc.exists()) {
                const playerData = doc.data();
                commit('setPlayerData', {
                  id: doc.id,
                  playerName: playerData.playerName,
                  skillSelf: playerData.skillSelf,
                  photoURL: playerData.photoURL,
                  homeLocation: playerData.homeLocation,
                  setupComplete: playerData.setupComplete ? playerData.setupComplete : false,
                  userId: playerData.userId,
                  homePage: playerData.homePage,
                  diamonds: playerData.diamonds ? playerData.diamonds : 0,
                  medalBronze: playerData.medalBronze ? playerData.medalBronze : 0,
                  medalSilver: playerData.medalSilver ? playerData.medalSilver : 0,
                  medalGold: playerData.medalGold ? playerData.medalGold : 0,
                  adminLocations: playerData.adminLocations ? playerData.adminLocations : 0,
                });
                commit('setPlayerListenerStatus', 'success');
              }
            },
            (error) => {
              console.error("Error listening to player data: ", error);
              commit('setPlayerListenerStatus', 'error');
            }
          );

          const unsubscribePlayersPrivate = onSnapshot(
            playerPrivateDocRef,
            (doc) => {
              if (doc.exists()) {
                const { lastLat, lastLon } = doc.data();
                commit('setLastLocation', { lastLat, lastLon });
              } else {
                commit('clearLastLocation');
              }
            },
            (error) => {
              console.error("Error listening to playersPrivate data: ", error);
              commit('clearLastLocation');
            }
          );

          commit('setPlayerListenerUnsub', () => {
            unsubscribePlayers();
            unsubscribePlayersPrivate();
          });
        } else {
          console.error("No player document found with this userId");
          commit('setPlayerListenerStatus', 'error');
        }
      } catch (error) {
        console.error("Error querying player data: ", error);
        commit('setPlayerListenerStatus', 'error');
      }
    },
    async startVersionListener({ commit }) {
      try {
        const versionRef = doc(db, 'version', 'current');
        const unsubscribe = onSnapshot(
          versionRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const currentVersion = docSnapshot.data().value;
              const loadedVersion = localStorage.getItem('loadedVersion');
              if (loadedVersion && loadedVersion !== currentVersion) {
                localStorage.setItem('loadedVersion', currentVersion);
                // location.reload();
              } else {
                localStorage.setItem('loadedVersion', currentVersion);
              }
              commit('setVersion', currentVersion);
            }
          },
          (error) => {
            console.error('VX: Error listening for version changes: ', error);
          }
        );
        commit('setVersionListenerUnsub', unsubscribe);
      } catch (error) {
        console.error('VX: Error setting up version listener: ', error);
      }
    },
    stopVersionListener({ state, commit }) {
      if (state.versionListenerUnsub) {
        state.versionListenerUnsub();
        commit('setVersionListenerUnsub', null);
      }
    },
    async fetchUserOnboardingStatus({ commit }, userId) {
      try {
        commit('setPlayerListenerStatus', 'loading');
        const privateQuery = query(collection(db, 'playersPrivate'), where('userId', '==', userId));
        const privateSnapshot = await getDocs(privateQuery);

        if (!privateSnapshot.empty) {
          const playerPrivateDoc = privateSnapshot.docs[0];
          const playerPrivateId = playerPrivateDoc.id;
          const playerDocRef = doc(db, 'players', playerPrivateId);
          const playerDoc = await getDoc(playerDocRef);

          if (playerDoc.exists()) {
            commit('setPlayerListenerStatus', 'success');
            commit('setUserOnboardingStatus', 'completed');
            return;
          }
        }

        const stageQuery = query(collection(db, 'playerStage'), where('userId', '==', userId));
        const stageSnapshot = await getDocs(stageQuery);

        if (!stageSnapshot.empty) {
          commit('setUserOnboardingStatus', 'onboarding');
        } else {
          commit('setUserOnboardingStatus', 'not_found');
        }
        commit('setPlayerListenerStatus', 'success');
      } catch (error) {
        console.error('Error fetching user onboarding status:', error);
        commit('setUserOnboardingStatus', 'error');
        commit('setPlayerListenerStatus', 'error');
      }
    },
    async startMyLocationsListener({ commit, dispatch, state }, userId) {
      if (state.myLocationsListenerUnsub) {
        state.myLocationsListenerUnsub();
      }
      commit('setMyLocationsRawLoaded', false);

      try {
        const playersRef = collection(db, 'players');
        const playerQuery = query(playersRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(playerQuery);

        if (!querySnapshot.empty) {
          const playerDoc = querySnapshot.docs[0];
          const playerId = playerDoc.id;

          const locationQuery = query(
            collection(db, 'playerLocations'),
            where('playerId', '==', playerId),
            where('removed', '==', false)
          );

          // console.log('🔵 Setting up myLocationsRaw listener for playerId:', playerId);
          const unsub = onSnapshot(
            locationQuery,
            (snapshot) => {
              const locations = snapshot.docs.map((doc) => doc.data().locationId);
              // console.log('🟣 myLocationsRaw fetched:', locations);
              commit('setMyLocationsRaw', locations);
              commit('setMyLocationsRawLoaded', true);
              dispatch('startMyPlayersRawListener');
              dispatch('recalculateMyPlayersRaw');
              dispatch('fetchMyLocations'); // Trigger fetchMyLocations when myLocationsRaw updates
            },
            (error) => {
              // console.error('🔴 Error in myLocationsRaw listener:', error);
              commit('setMyLocationsRawLoaded', false);
            }
          );
          commit('setMyLocationsListenerUnsub', unsub);
        } else {
          console.error('No player document found for userId:', userId);
          commit('setMyLocationsRaw', []);
          commit('setMyLocationsRawLoaded', true);
          dispatch('fetchMyLocations'); // Ensure myLocations is cleared when raw is empty
        }
      } catch (error) {
        console.error('Error setting up myLocations listener:', error);
        commit('setMyLocationsRawLoaded', false);
      }
    },
    async startMyPlayersRawListener({ state, commit, dispatch }) {
      try {
        // Load cached myPlayersRaw from IndexedDB initially
        const cachedPlayersRaw = await get('myPlayersRaw');
        if (cachedPlayersRaw && Array.isArray(cachedPlayersRaw.data)) {
          // console.log('⚡ Loaded myPlayersRaw from cache');
          commit('setMyPlayersRaw', cachedPlayersRaw.data);
        }

        // Set up listeners for each favorite location
        const listeners = state.myLocationsRaw.map((locationId) => {
          return onSnapshot(
            query(collection(db, 'playerLocations'), where('locationId', '==', locationId)),
            (snapshot) => {
              const playerIds = snapshot.docs.map((doc) => doc.data().playerId);
              // console.log(`📍 Fetched playerIds for location ${locationId}:`, playerIds);
              commit('updateMyPlayersRawMap', { locationId, playerIds });
              dispatch('fetchMyPlayers');
            },
            (error) => console.error(`🔴 Error in listener for ${locationId}:`, error)
          );
        });

        return () => listeners.forEach((unsubscribe) => unsubscribe());
      } catch (error) {
        console.error('🔴 Error starting myPlayersRaw listener:', error);
      }
    },
    async fetchMyPlayers({ state, commit }) {
      try {
        // Load cached myPlayers from IndexedDB for immediate display
        const cachedPlayers = await get('myPlayers');
        if (cachedPlayers && Array.isArray(cachedPlayers.data) && cachedPlayers.data.length > 0) {
          // console.log('⚡ Loaded myPlayers from cache:', cachedPlayers.data);
          commit('setMyPlayers', cachedPlayers.data);
        }

        // Fetch from Firestore using the current state.myPlayersRaw
        if (state.myPlayersRaw.length === 0) {
          // console.log('🟡 myPlayersRaw is empty, setting myPlayers to []');
          commit('setMyPlayers', []);
          return;
        }

        // console.log('👋 Fetching myPlayers with myPlayersRaw:', state.myPlayersRaw);

        const batchSize = 10;
        const batches = [];
        for (let i = 0; i < state.myPlayersRaw.length; i += batchSize) {
          batches.push(state.myPlayersRaw.slice(i, i + batchSize));
        }

        const playerQueries = batches.map((batch) =>
          getDocs(query(collection(db, 'players'), where(documentId(), 'in', batch)))
        );

        const results = await Promise.all(playerQueries);
        const players = results.flatMap((result) => result.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })));

        const myPlayers = players.map((player) => ({
          playerId: player.id,
          playerName: player.playerName,
          skillSelf: player.skillSelf,
          created: player.created,
          photoURL: player.photoURL,
        }));

        // console.log('🦄 Fetched and formatted myPlayers:', myPlayers);
        commit('setMyPlayers', myPlayers);
        // Update IndexedDB with the latest myPlayers
        await set('myPlayers', { data: myPlayers });
      } catch (error) {
        console.error('🔴 Error fetching myPlayers:', error);
      }
    },
    async recalculateMyPlayersRaw({ state, commit, dispatch }) {
      try {
        if (state.myLocationsRaw.length === 0) {
          commit('setMyPlayersRaw', []);
          await set('myPlayersRaw', { data: [] });
          dispatch('fetchMyPlayers');
          return;
        }
    
        // Fetch player IDs for current locations
        const playerQueries = state.myLocationsRaw.map(locationId =>
          getDocs(query(collection(db, 'playerLocations'), where('locationId', '==', locationId)))
        );
        const results = await Promise.all(playerQueries);
        const allPlayerIds = new Set();
        results.forEach(result => {
          result.docs.forEach(doc => allPlayerIds.add(doc.data().playerId));
        });
        const updatedPlayersRaw = Array.from(allPlayerIds);
    
        // Update state and IndexedDB
        commit('setMyPlayersRaw', updatedPlayersRaw);
        await set('myPlayersRaw', { data: updatedPlayersRaw });
        dispatch('fetchMyPlayers'); // Refresh myPlayers based on new myPlayersRaw
      } catch (error) {
        console.error('🔴 Error recalculating myPlayersRaw:', error);
      }
    },
    stopMyLocationsListener({ state, commit }) {
      if (state.myLocationsListenerUnsub) {
        state.myLocationsListenerUnsub();
        commit('setMyLocationsListenerUnsub', null);
        commit('setMyLocationsRaw', []);
        commit('setMyLocationsRawLoaded', false);
        commit('setMyLocations', []); // Clear myLocations on stop
      }
    },
    stopMyPlayersRawListener({ state, commit }) {
      state.myPlayersRawListenerUnsubs.forEach((unsub) => unsub());
      commit('setMyPlayersRawListenerUnsubs', []);
      commit('setMyPlayersRaw', []);
      commit('setMyPlayersRawLoaded', false);
      commit('setMyPlayers', []);
    },
    async startMyLocationsAdminListener({ commit, state }, userId) {
      if (state.myLocationsAdminListenerUnsub) {
        state.myLocationsAdminListenerUnsub();
      }
      commit('setMyLocationsAdminRawLoaded', false);

      try {
        const playersRef = collection(db, 'players');
        const playerQuery = query(playersRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(playerQuery);

        if (!querySnapshot.empty) {
          const playerDoc = querySnapshot.docs[0];
          const playerId = playerDoc.id;

          const adminQuery = query(
            collection(db, 'locationsAdmin'),
            where('playerId', '==', playerId)
          );

          const unsub = onSnapshot(
            adminQuery,
            (snapshot) => {
              const locations = snapshot.docs.map((doc) => doc.data().locationId);
              commit('setMyLocationsAdminRaw', locations);
              commit('setMyLocationsAdminRawLoaded', true);
            },
            (error) => {
              console.error('Error in myLocationsAdminRaw listener:', error);
              commit('setMyLocationsAdminRawLoaded', false);
            }
          );
          commit('setMyLocationsAdminListenerUnsub', unsub);
        } else {
          console.error('No player document found for userId:', userId);
          commit('setMyLocationsAdminRaw', []);
          commit('setMyLocationsAdminRawLoaded', true);
        }
      } catch (error) {
        console.error('Error setting up myLocationsAdmin listener:', error);
        commit('setMyLocationsAdminRawLoaded', false);
      }
    },
    stopMyLocationsAdminListener({ state, commit }) {
      if (state.myLocationsAdminListenerUnsub) {
        state.myLocationsAdminListenerUnsub();
        commit('setMyLocationsAdminListenerUnsub', null);
        commit('setMyLocationsAdminRaw', []);
        commit('setMyLocationsAdminRawLoaded', false);
      }
    },
    // New action for myLocations
    async fetchMyLocations({ state, commit }) {
      if (state.myLocationsRaw.length === 0) {
        // console.log('🌍 myLocationsRaw is empty, setting myLocations to []');
        commit('setMyLocations', []);
        commit('setMyLocationsRawMap', {});
        return;
      }

      // console.log('🌎 Fetching myLocations with myLocationsRaw:', state.myLocationsRaw);

      const batchSize = 10;
      const batches = [];
      for (let i = 0; i < state.myLocationsRaw.length; i += batchSize) {
        batches.push(state.myLocationsRaw.slice(i, i + batchSize));
      }

      const locationQueries = batches.map((batch) =>
        getDocs(query(collection(db, 'locations'), where(documentId(), 'in', batch)))
      );

      try {
        const results = await Promise.all(locationQueries);
        const locations = results.flatMap((result) => result.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          courts: doc.data().courts,
          lat: doc.data().lat,
          lon: doc.data().lon,
        })));

        // Update myLocationsRawMap (optional, for consistency with myPlayersRawMap)
        const locationsMap = {};
        locations.forEach((loc) => {
          locationsMap[loc.id] = loc;
        });
        commit('setMyLocationsRawMap', locationsMap);

        // Update myLocations directly in state
        commit('setMyLocations', locations);
      } catch (error) {
        console.error('🔴 Error fetching myLocations:', error);
      }
    },
    // Friends
    async startMyFriendsListener({ commit, state }, userId) {
      // Stop any existing listener
      if (state.myFriendsListenerUnsub) {
        state.myFriendsListenerUnsub();
      }
      commit('setMyFriendsRawLoaded', false);

      try {
        // Fetch playerId from players collection using userId
        const playersRef = collection(db, 'players');
        const playerQuery = query(playersRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(playerQuery);

        if (!querySnapshot.empty) {
          const playerDoc = querySnapshot.docs[0];
          const playerId = playerDoc.id;

          // Query playerFriends for friends where playerId matches and friend is true
          const friendsQuery = query(
            collection(db, 'playerFriends'),
            where('playerId', '==', playerId),
            where('friend', '==', true)
          );

          const unsub = onSnapshot(
            friendsQuery,
            (snapshot) => {
              const friends = snapshot.docs.map((doc) => doc.data().friendId);
              commit('setMyFriendsRaw', friends);
              commit('setMyFriendsRawLoaded', true);
            },
            (error) => {
              console.error('Error in myFriendsRaw listener:', error);
              commit('setMyFriendsRawLoaded', false);
            }
          );
          commit('setMyFriendsListenerUnsub', unsub);
        } else {
          console.error('No player document found for userId:', userId);
          commit('setMyFriendsRaw', []); // Empty array if no player found
          commit('setMyFriendsRawLoaded', true);
        }
      } catch (error) {
        console.error('Error setting up myFriends listener:', error);
        commit('setMyFriendsRawLoaded', false);
      }
    },
    stopMyFriendsListener({ state, commit }) {
      if (state.myFriendsListenerUnsub) {
        state.myFriendsListenerUnsub();
        commit('setMyFriendsListenerUnsub', null);
        commit('setMyFriendsRaw', []);
        commit('setMyFriendsRawLoaded', false);
      }
    },
    // Favorites
    async startMyFavoritesListener({ commit, state }, userId) {
      // Stop any existing listener
      if (state.myFavoritesListenerUnsub) {
        state.myFavoritesListenerUnsub();
      }
      commit('setMyFavoritesRawLoaded', false);

      try {
        // Fetch playerId from players collection using userId
        const playersRef = collection(db, 'players');
        const playerQuery = query(playersRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(playerQuery);

        if (!querySnapshot.empty) {
          const playerDoc = querySnapshot.docs[0];
          const playerId = playerDoc.id;

          // Query playerFavorites for Favorites where playerId matches and favorite is true
          const favoritesQuery = query(
            collection(db, 'playerFavorites'),
            where('playerId', '==', playerId),
            where('favorite', '==', true)
          );

          const unsub = onSnapshot(
            favoritesQuery,
            (snapshot) => {
              const favorites = snapshot.docs.map((doc) => doc.data().favoriteId);
              commit('setMyFavoritesRaw', favorites);
              commit('setMyFavoritesRawLoaded', true);
            },
            (error) => {
              console.error('Error in myFavoritesRaw listener:', error);
              commit('setMyFavoritesRawLoaded', false);
            }
          );
          commit('setMyFavoritesListenerUnsub', unsub);
        } else {
          console.error('No player document found for userId:', userId);
          commit('setMyFavoritesRaw', []); // Empty array if no player found
          commit('setMyFavoritesRawLoaded', true);
        }
      } catch (error) {
        console.error('Error setting up myFavorites listener:', error);
        commit('setMyFavoritesRawLoaded', false);
      }
    },
    stopMyFavoritesListener({ state, commit }) {
      if (state.myFavoritesListenerUnsub) {
        state.myFavoritesListenerUnsub();
        commit('setMyFavoritesListenerUnsub', null);
        commit('setMyFavoritesRaw', []);
        commit('setMyFavoritesRawLoaded', false);
      }
    },
  },
  getters: {
    user: (state) => state.user,
    isLoggedIn: (state) => !!state.user,
    isAppSleeping: (state) => state.isAppSleeping,
    curPlayerData: (state) => state.curPlayerData,
    version: (state) => state.version,
    userOnboardingStatus: (state) => state.userOnboardingStatus,
  },
});