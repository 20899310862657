import { functions } from '@/firebase/config'
import { serverTimestamp } from 'firebase/firestore'
import { httpsCallable } from "firebase/functions";

const updateLocationPhoto = async (locationId, durl) => {
    const editLocation = httpsCallable(functions, 'documentUpdates-setDocument')
    try {
        await editLocation({
            collection: "locations",
            id: locationId,
            merge: true,
            fields: {
                photoURL: durl + '&dnpa=' + Date.now(),
                edited: serverTimestamp(),
            }
        })
    } catch (error) {
        console.error(`Error editing locatiion in cloud function documentUpdates-setDocument`, error);
    }

    return
}
export default updateLocationPhoto