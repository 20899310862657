<template>
  <div v-if="modelValue" class="modal-overlay">
    <div class="modal-content">
      <i class="fa-solid fa-triangle-exclamation info-icon-large"></i>
      <div v-if="heading" class="normal-heading">{{ heading }}</div>
      <div class="normal-subheading">{{ message }}</div>
      <button @click="closeModal" class="button-large-small-text">OK</button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      default: '', // Optional heading
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const closeModal = () => {
      emit('update:modelValue', false) // Close the modal
    }

    return {
      closeModal,
    }
  },
})
</script>
