import { ref, onUnmounted, getCurrentInstance } from 'vue'
import { db } from '@/firebase/config'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { format, addDays } from 'date-fns'
import getEventAccessLevel from "@/composables/getEventAccessLevel"


export default function useEventCountsPrivate(user_slug) {
    const eventCountsPrivate = ref([])
    const locationsUnsubscribePrivate = ref(null)
    const eventsUnsubscribePrivate = ref(null)

    const fetchEventCountsPrivate = () => {
        const today = format(new Date(), 'yyyy-MM-dd')
        const futureDate = format(addDays(new Date(), 60), 'yyyy-MM-dd')

        // Step 1: Query favorite locations
        const playerLocationsRefPrivate = collection(db, 'playerLocations')
        const locationsQueryPrivate = query(
            playerLocationsRefPrivate,
            where('playerId', '==', user_slug),
            where('removed', '!=', true)
        )

        locationsUnsubscribePrivate.value = onSnapshot(locationsQueryPrivate, (snapshot) => {
            const locationIds = snapshot.docs.map(doc => doc.data().locationId)

            if (locationIds.length === 0) {
                eventCountsPrivate.value = [] // No favorite locations, reset event countsPrivate
                return
            }

            // Step 2: Query future events for favorite locations
            const eventsRefPrivate = collection(db, 'events')
            const eventsQueryPrivate = query(
                eventsRefPrivate,
                where('startDate', '>=', today),
                where('startDate', '<=', futureDate),
                where('invite', '==', true),
                where('location', 'in', locationIds) // 'location' refers to locationId
            )

            // Unsubscribe from any previous events listener before setting a new one
            if (eventsUnsubscribePrivate.value) eventsUnsubscribePrivate.value()

            eventsUnsubscribePrivate.value = onSnapshot(eventsQueryPrivate, async (eventSnapshot) => {
                const countsPrivate = {}

                // Process each event document in the snapshot
                for (const doc of eventSnapshot.docs) {
                    const { startDate, location, deleted } = doc.data()

                    // Skip events that are marked as deleted
                    if (deleted === true) continue

                    // Check invite access level status asynchronously
                    const { accessLevel } = await getEventAccessLevel(doc.id, user_slug)

                    // If access level is not 'full', we treat it as deleted
                    if (accessLevel !== 'full') {
                        // console.log('Access not full, skipping event:', doc.id)
                        continue
                    }

                    const key = `${startDate}_${location}`

                    if (!countsPrivate[key]) {
                        countsPrivate[key] = { date: startDate, locationId: location, eventCount: 0 }
                    }
                    countsPrivate[key].eventCount += 1
                }

                // Set the private event counts to the value
                eventCountsPrivate.value = Object.values(countsPrivate)
            })
        })
    }

    fetchEventCountsPrivate()

    // Cleanup both listeners on unmount
    if (getCurrentInstance()) {
        onUnmounted(() => {
            if (locationsUnsubscribePrivate.value) locationsUnsubscribePrivate.value()
            if (eventsUnsubscribePrivate.value) eventsUnsubscribePrivate.value()
        })
    }

    return { eventCountsPrivate }
}
