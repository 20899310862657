<template>
  <Smash :isVisible="!setPlayerName" labelText="Loading player..." labelSubtext="almost done"></Smash>
  <PlayerNameSelect v-if="setPlayerName" :uid="curUserId" @playerNameSet="handlePlayerNameSet" />
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { db, auth } from '@/firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import { signOut } from 'firebase/auth'
import PlayerNameSelect from '@/components/PlayerNameSelect.vue'
import Smash from '@/components/Smash.vue'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'home',
  components: {
    PlayerNameSelect,
    Smash,
  },
  setup() {
    const router = useRouter()
    const store = useStore() // Access Vuex store
    const setPlayerName = ref(false)
    const curUserId = store.state.user?.uid

    const pendingEvent = Cookies.get('pendingEvent') // new for pending event

    const confirmPlayerReady = async (playerId) => {
      try {
        const docSnap = await getDoc(doc(db, 'players', playerId))
        if (docSnap.exists()) {
          const locationFromCookie = ''
          if (docSnap.data().homeLocation && docSnap.data().skillSelf && docSnap.data().setupComplete) {
            if (pendingEvent) {
              if (pendingEvent.startsWith('/vieweditevent/')) {
                router.push(pendingEvent)
                Cookies.remove('pendingEvent')
                Cookies.remove('pendingEventId')
                Cookies.remove('pendingLocation')
                return // stop execution
              } else {
                // bad event link
                Cookies.remove('pendingEvent')
                Cookies.remove('pendingEventId')
                Cookies.remove('pendingLocation')
              }
            }

            // const locationToUse = locationFromCookie || docSnap.data().homeLocation

            let routeTo = docSnap.data().homePage === 'myEvents' ? 'MyEvents' : docSnap.data().homePage === 'list' ? 'EventList' : 'Calendar'
            console.log('routeTo', routeTo)
            router.push({ name: routeTo })
            // router.push({ name: 'Calendar' })
            // router.push({ name: 'events', params: { locationId: locationToUse } })
          } else if (docSnap.data().homeLocation && docSnap.data().skillSelf && !docSnap.data().setupComplete) {
            console.log('pushing to welcome')
            router.push({ name: 'welcome' })
          } else {
            console.log('player setup incomplete')
            router.push({ name: 'playerSetup' })
          }
        } else {
          console.log('Player not found!')
          signOut(auth)
          router.push({ name: 'landing' })
        }
      } catch (error) {
        console.error('An error occurred during player confirmation:', error)
      }
    }

    const handlePlayerNameSet = () => {
      console.log('handlePlayerNameSet')
      setPlayerName.value = false
      location.reload() // hacky solution
    }

    onMounted(async () => {
      if (!curUserId) {
        alert('Error (1A): No user authenticated!')
        await signOut(auth)
        router.push({ name: 'landing' })
        return
      }

      // Trigger Vuex action to fetch onboarding status
      await store.dispatch('fetchUserOnboardingStatus', curUserId)

      const onboardingStatus = store.state.userOnboardingStatus

      if (onboardingStatus === 'not_found') {
        alert('Error (1A): Player not found!')
        await signOut(auth)
        router.push({ name: 'landing' })
      } else if (onboardingStatus === 'completed') {
        // console.log('User name set, confirming player ready.')

        // Wait up to 10 seconds for the listener status to change
        const maxWaitTime = 10000 // 10 seconds
        const interval = 100
        let waitedTime = 0

        while (store.state.playerListenerStatus === 'loading' && waitedTime < maxWaitTime) {
          await new Promise((resolve) => setTimeout(resolve, interval))
          waitedTime += interval
        }

        // Check the final status after the wait loop
        if (store.state.playerListenerStatus === 'success') {
          await confirmPlayerReady(store.state.curPlayerData?.id)
        } else if (store.state.playerListenerStatus === 'error') {
          alert('Error (1B): Unable to load player data.')
          await signOut(auth)
          router.push({ name: 'landing' })
        } else {
          console.error('Timeout waiting for playerListenerStatus to resolve.')
          alert('Error (1C): System timeout. Please try again.')
          await signOut(auth)
          router.push({ name: 'landing' })
        }
      } else if (onboardingStatus === 'onboarding') {
        // console.log('No player name set.')
        setPlayerName.value = true
      } else {
        console.error('Unexpected onboarding status:', onboardingStatus)
        alert('System error occurred.')
        await signOut(auth)
        router.push({ name: 'landing' })
      }
    })

    return {
      setPlayerName,
      curUserId,
      handlePlayerNameSet,
    }
  },
}
</script>
