<template>
  <div class="ev">
    <div class="ev-block">
      <h1>Out of bounds!</h1>
      <h3>(404 page not found)</h3>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    onMounted(() => {
      router.push({ name: "home" }); // Redirect to home route
    });

    return {};
  },
};
</script>

<style>
</style>
