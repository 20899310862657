import { ref, watchEffect } from 'vue'
// firebase imports
import { db } from '@/firebase/config'
import { collection, onSnapshot, query, where, orderBy, limit } from 'firebase/firestore'

const getAlerts = (pId) => {
    const documents = ref(null)
    const error = ref(null)
    let collectionRef = query(
        collection(db, 'alerts'),
        where("to", "==", pId),
        orderBy("created", "desc"),
        limit(99)
    );

    // update all as read

    // now get
    const unsub = onSnapshot(collectionRef, snapshot => {
        // console.log('snapshot taken')
        let results = []
        snapshot.docs.forEach(doc => {
            doc.data().created && results.push({ ...doc.data(), id: doc.id })
        })
        // update values
        documents.value = results
        // console.log('FOUND!!:', documents.value.length)
        error.value = null
    }, (err) => {
        console.log(err.message)
        documents.value = null
        error.value = 'could not get data'
    })
    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())

    })
    return { documents, error }
}


export default getAlerts