<template>
  <div class="preference-container">
    <div class="theme-container">
      <label id="themeIcon" @click="toggleTheme" aria-label="Toggle theme">
        <i :class="themeIcon"></i>
      </label>
    </div>
    <div class="language-container">
      <label id="languageIcon" for="languageSelect">
        <i class="fa-solid fa-globe"></i>
      </label>
      <select name="languageSelect" v-model="selectedLanguage" @change="emitLanguageChange" aria-label="Language select">
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import Cookies from 'js-cookie'

export default {
  name: 'PreferenceSelector',
  props: {
    initialLanguage: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Language handling
    const selectedLanguage = ref(props.initialLanguage)
    const emitLanguageChange = () => {
      emit('language-changed', selectedLanguage.value)
    }

    watch(selectedLanguage, () => {
      emitLanguageChange()
    })

    // Theme handling
    const theme = ref(Cookies.get('theme') || 'light')
    const themeIcon = ref(theme.value === 'dark' ? 'fa-solid fa-moon' : 'fa-solid fa-sun')

    const toggleTheme = () => {
      theme.value = theme.value === 'light' ? 'dark' : 'light'
      themeIcon.value = theme.value === 'dark' ? 'fa-solid fa-moon' : 'fa-solid fa-sun'
      document.documentElement.setAttribute('data-theme', theme.value)
      Cookies.set('theme', theme.value, { expires: 365 })
    }

    onMounted(() => {
      document.documentElement.setAttribute('data-theme', theme.value)
    })

    return {
      selectedLanguage,
      emitLanguageChange,
      themeIcon,
      toggleTheme,
    }
  },
}
</script>

<style scoped>
.preference-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--width-default);
  margin-top: var(--margin-small);
}

.preference-container i {
  color: var(--button-color);
}

.preference-container i:hover {
  color: var(--button-hover-color);
}

.language-container {
  display: flex;
  align-items: center;
}

.language-container i {
  color: var(--button-text-color);
}

.theme-container {
  display: flex;
  align-items: center;
}

#themeIcon {
  cursor: pointer;
  font-size: var(--font-size-title);
  color: var(--button-text-color);
}




/* NOTE: hard-coded fonts here are needed to line up the selector and the icon to the pixel */
#languageIcon {
  display: inline-block;
  padding: 8px 10px;
  background-color: var(--button-color);
  text-align: center;
  border-radius: 10px 0px 0px 10px;
  font-size: 20px;
}

.language-container select {
  font-size: 20px;
  color: var(--button-color);
  height: 40px;
  text-align: right;
  max-width: 100vw;
  border-radius: 0 10px 10px 0;
  border: solid 1px var(--button-disabled-color);
  background: var(--select-background-color);
}

@media screen and (max-width: 489px) {
  #languageIcon {
    padding: 6px 8px;
    border-radius: 8px 0px 0px 8px;
    font-size: 18px;
  }
  .language-container select {
    font-size: 18px;
    height: 34px;
    border-radius: 0 8px 8px 0;
  }
}

@media screen and (max-width: 374px) {
  #languageIcon {
    padding: 6px 8px;
    border-radius: 6px 0px 0px 6px;
    font-size: 16px;
  }
  .language-container select {
    font-size: 16px;
    height: 30px;
    border-radius: 0 6px 6px 0;
  }
}
</style>
