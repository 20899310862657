<template>
  <Smash :isVisible="loadingData" :labelText="labelText" :labelSubtext="labelSubtext" />
  <div class="menu" v-if="!loadingData && user_slug && user_name">
    <MenuHamburger @click="handleMenuToggle" :user_slug="user_slug" aria-label="Toggle Menu" />
    <MenuPlayer v-if="playerData" :user_name="user_name" :playerData="playerData" />
    <MenuAlert :unreadCount="unreadAlerts" aria-label="View Alerts" />
    <!-- <MenuSearch @search="handleSearch" aria-label="Search" /> -->
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import MenuHamburger from '@/components/MenuHamburger.vue'
import MenuPlayer from '@/components/MenuPlayer.vue'
import MenuAlert from '@/components/MenuAlert.vue'
import MenuSearch from '@/components/MenuSearch.vue'
import Smash from '@/components/Smash.vue'
import usePlayerState from '@/composables/usePlayerState'
import getAlertCount from '@/composables/getAlertCount'

export default {
  name: 'Menu',
  components: {
    MenuHamburger,
    MenuPlayer,
    MenuAlert,
    MenuSearch,
    Smash,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const isMenuOpen = ref(false) // Track menu state
    const labelText = ref('Loading...')
    const labelSubtext = ref('almost done')
    const user_slug = ref('')
    const user_name = ref('')
    const loadingData = ref(false)
    const unreadAlerts = ref(0)
    const { theme, playerData, waitForPlayerData } = usePlayerState()
    document.documentElement.setAttribute('data-theme', theme.value)

    // Initialize isMenuOpen based on the current route when mounted
    onMounted(async () => {
      isMenuOpen.value = route.name === 'menuitems'
      loadingData.value = true
      const success = await waitForPlayerData()
      loadingData.value = false
      if (success) {
        user_slug.value = playerData.value.id
        user_name.value = playerData.value.playerName
        const { docCount } = getAlertCount(user_slug.value)
        watchEffect(() => {
          unreadAlerts.value = docCount.value
          // console.log('Updated unreadAlerts:', unreadAlerts.value)
        })
      }
    })

    const handleMenuToggle = () => {
      if (isMenuOpen.value) {
        router.push({ name: 'home' })
        // router.push({ name: 'Calendar' }) // Navigate to home
      } else {
        // console.log('Menu toggle clicked - opening menu');
        router.push({ name: 'menuitems' }) // Navigate to menu
      }
    }

    // Watch the route and update the `isMenuOpen` state accordingly
    watch(
      () => route.name,
      (newRouteName) => {
        isMenuOpen.value = newRouteName === 'menuitems'
      }
    )
    onUnmounted(() => {
        // No cleanup needed, but this resolves the warning
    })
    return {
      handleMenuToggle,
      labelText,
      labelSubtext,
      user_slug,
      loadingData,
      user_name,
      playerData,
      unreadAlerts,
    }
  },
  methods: {
    handleSearch() {
      console.log('Search clicked')
      // Logic for search
    },
  },
}
</script>

<style scoped>
.menu {
  /* position: fixed; */
  /* bottom: 0; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-regular);
  background-color: var(--background-color);
  color: var(--text-color);
  margin-right: var(--padding-small);
  width: 96%;
  z-index: 999999;
}

.menu i {
  color: var(--button-color);
  font-size: var(--font-size-menuIcon);
  cursor: pointer;
  padding-left: var(--padding-small);
  padding-right: var(--padding-small);
}
</style>
