import getPlayer from "@/composables/getPlayer"
import getEvent from "@/composables/getEvent"
import isFriendOfPlayer from "@/composables/isFriendOfPlayer"
import isFavoriteOfPlayer from "@/composables/isFavoriteOfPlayer"
import isInClub from "@/composables/isInClub"
import { format } from "date-fns";

const getEventAccessLevel = async (e, u) => { // event ID, player ID -> returns access level for this user to this event
    try {
        // console.log('e/u', e, u)
        let accessLevel = 'full'
        let reason = ''
        // get player record
        const playerRec = await getPlayer(u)
        // console.log('PlayerRecord', playerRec.value)

        // get event record
        const eventRec = await getEvent(e)
        // console.log('eventRec:', eventRec.value)

        let today = new Date()
        let compareDate = format(today, 'yyyy-MM-dd')
        if (eventRec.value.startDate < compareDate) {
            // console.log('dates: ', eventRec.value.startDate, ' and ', today.toISOString().split("T")[0])
            accessLevel = 'read'
            reason = 'This event is in the past'
        } else {
            // player skill level checks
            if (playerRec.value.skillSelf >= eventRec.value.skillMin && playerRec.value.skillSelf <= eventRec.value.skillMax) {
                // console.log('Player skill meets range for this event')
            } else { // player skill does not meet event criteria
                accessLevel = 'read'
                // console.log('Player skill of ' + playerRec.value.skillSelf + ' does NOT meet event range of ' + eventRec.value.skillMin + ' to ' + eventRec.value.skillMax)
                if (playerRec.value.skillSelf < eventRec.value.skillMin) {
                    // console.log('Player skill too low')
                    reason = 'Your skill is too low for this event'
                } else { // must be too high
                    // console.log('Player skill too high')
                    reason = 'Your skill is too high for this event'
                }
            }

            // invite only checks if player on the invite list
            if (eventRec.value.invite) {
                let playerInvited = false // default to false
                if (eventRec.value.playerId == u) { // this player created the event so they are invited automatically
                    playerInvited = true
                } else { // this player doesn't own private event so check invite list
                    if (eventRec.value.invited == 'Friends') {
                        if (await isFriendOfPlayer(eventRec.value.playerId, u)) {
                            // console.log('AM FRIENDS')
                            playerInvited = true
                        }
                    } else if (eventRec.value.invited == 'Favorites') {
                        if (await isFavoriteOfPlayer(eventRec.value.playerId, u)) {
                            // console.log('AM FAVORITE')
                            playerInvited = true
                        }
                    } else { // club
                        if (await isInClub(eventRec.value.playerId, u, eventRec.value.location, eventRec.value.invited)) {
                            // console.log('IN DA CLUB!')
                            playerInvited = true
                        }

                    }
                }
                // set access level to none if not invited
                if (!playerInvited) {
                    // console.log('Sorry, you are NOT on the list.')
                    accessLevel = 'none'
                }
            }
        }

        return { accessLevel, reason }

    } catch (error) {
        console.error('Error in getEventAccessLevel:', error.message);
    }
}
export default getEventAccessLevel