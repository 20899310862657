<template>
  <form>
    <div class="messageSend">
      <textarea placeholder="Type message then click reply" v-model="message"></textarea>
      <div class="form-block">
        <button>Reply <i class="fa-solid fa-reply"></i></button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from '@vue/reactivity';
import getUser from "@/composables/getUser";
import { serverTimestamp } from '@firebase/firestore';
import { db, functions } from "@/firebase/config";
import { collection, addDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export default {
  name: "sendAlertForm",
  props: ["pId"],
  setup(props) {
    document.body.scrollTop = document.documentElement.scrollTop = 0; // always sc   
    console.log('prop', props)
    const { user } = getUser()
    const message = ref('')
    const handleSubmit = async () => {
      const chat = {
        userId: user.value.uid,
        message: message.value,
        created: serverTimestamp()
      }
      console.log('CHAT:', chat)
      const addAlert = httpsCallable(functions, 'documentUpdates-addDocument')
      try {
        await addAlert({
         collection: "eventMatches",
         fields: chat
        })
      } catch (error) {
        console.error(`Error creating eventMatches chat in cloud function documentUpdates-addDocument`, error);
      }

      console.log("Document written with ID: ", docRef.id);
      message.value = ''
    }


    return { message, handleSubmit }
  }
}
</script>

