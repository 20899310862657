<template>
  <div v-if="visible" class="celebration-overlay" @click="hidePopup">
    <div class="emoji-display">{{ emoji }}</div>
    <div class="celebrate-heading">{{ headingText }}</div>
    <div class="celebrate-subheading">{{ subheadingText }}</div>
    <canvas ref="confettiCanvas" class="confetti-canvas"></canvas>
  </div>
</template>


<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import confetti from 'canvas-confetti'

const props = defineProps({
  emoji: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
  headingText: {
    type: String,
    required: true,
  },
  subheadingText: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['hide'])
const visible = ref(props.isVisible)
const confettiCanvas = ref(null)

const hidePopup = () => {
  emit('hide') // Notify parent to update visibility state
}

const launchSchoolPrideConfetti = () => {
  if (confettiCanvas.value) {
    const myConfetti = confetti.create(confettiCanvas.value, {
      resize: true,
      useWorker: true,
    })
    myConfetti({
      particleCount: 500,
      spread: 75,
      angle: 60,
      origin: { x: 0 },
      colors: ['#fffc00', '#0063c8', '#ff38f5'],
    })
    myConfetti({
      particleCount: 500,
      spread: 75,
      angle: 120,
      origin: { x: 1 },
      colors: ['#fffc00', '#0063c8', '#ff38f5'],
    })
  }
}

onMounted(() => {
  if (props.isVisible) launchSchoolPrideConfetti()
  setTimeout(hidePopup, 5000) // Auto-hide after 5 seconds
})

onUnmounted(() => {
  // Ensure no memory leaks by cleaning up listeners or effects if needed
})
</script>

<style scoped>
.celebration-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: var(--background-color-tinytrans); /* Semi-transparent background */
  justify-content: center;
  z-index: 9999999;
}

.emoji-display {
  font-size: 8em;
  animation: pop-in 0.5s ease-out;
  margin-bottom: var(--margin-small);
}

.confetti-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@keyframes pop-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.celebrate-heading {
  color: var(--input-text-color);
  text-align: center;
  font-size: var(--font-size-title);
  font-weight: 500;
  margin: var(--margin-tiny) 0 var(--margin-tiny) 0;
  width: var(--width-default);
}

.celebrate-subheading {
  color: var(--input-text-color);
  text-align: center;
  font-size: var(--font-size-heading);
  font-weight: 300;
  margin: var(--margin-medium) 0 var(--margin-large) 0;
  width: var(--width-default);
}
</style>

  