import { db } from "@/firebase/config";
import { collection, onSnapshot, query, where, orderBy, limit } from "firebase/firestore";
import { ref, watchEffect } from 'vue'

const getAlertCount = (pId) => {
    const docCount = ref(null)
    const error = ref(null)
    // show the last 100 unread max only, too many players never update this and end up with thousands
    let collectionRef = query(
        collection(db, 'alerts'),
        where("to", "==", pId),
        where("read", "==", false),
        orderBy("created", "desc"),
        limit(99)
    );

    // now get
    const unsub = onSnapshot(collectionRef, snapshot => {
        // console.log('count taken')
        // console.log('SS', snapshot.size)
        if (snapshot.size > 0) {
            docCount.value = snapshot.size
        } else {
            docCount.value = 0
        }
        error.value = null
    }, (err) => {
        console.log('alerts', err.message)
        docCount.value = null
        error.value = 'could not get data'
    })
    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())

    })
    return { docCount, error }
}

export default getAlertCount

