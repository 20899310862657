<template>
  <Smash :isVisible="loadingData" :labelText="labelText" :labelSubtext="labelSubtext" />
  <div v-if="playerRec" class="outer-wrapper">
    <div class="profileImage-wrapper">
      <img :src="(playerRec.photoURL ? playerRec.photoURL : phProf )" alt="Img" />
      <img id="overlay" :src="profileOverlay" alt="Img" />
      <div id="playerName">{{ playerRec.playerName }}</div>
      <div id="playerJoined">
        <span style="font-weight: 500;">Drafted:</span>
        {{ draftDate(playerRec.created) }}
      </div>
      <div class="playerHighlightWrapper">
        <div class="playerHighlights">
          <i id="gold" class="fa-solid fa-medal">
            <span>{{ playerRec.medalGold ? playerRec.medalGold : '0' }}</span>
          </i>
          <i id="silver" class="fa-solid fa-medal">
            <span>{{ playerRec.medalSilver ? playerRec.medalSilver : '0' }}</span>
          </i>
          <i id="bronze" class="fa-solid fa-medal">
            <span>{{ playerRec.medalBronze ? playerRec.medalBronze : '0' }}</span>
          </i>
          <i id="trophy" class="fa-solid fa-gem">
            <span>{{ playerRec.diamonds ? playerRec.diamonds : '0' }}</span>
          </i>
        </div>
      </div>
    </div>

    <div class="eventOuterWrapper">
      <div class="form-block" style="margin-bottom: 0px; padding-bottom: 2px;">
        <button class="button-large-small-text stretch-item" @click="handleGoThread(playerRec.playerName, '')">
          Message {{ playerRec.playerName }}
          <i class="fa-solid fa-message"></i>
        </button>
      </div>
      <div class="ev-flex">
        <button class="button-large-small-text stretch-item" style="margin-right: 3px;" @click="tFriends(user_slug, playerRec.id, !playerFriends.includes(playerRec.id))">
          {{ playerFriends.includes(playerRec.id) ? 'Un-Friend ' : 'Add Friend ' }}
          <i class="fa-solid fa-user-group" :style="playerFriends.includes(playerRec.id) ? 'color: var(--theme-yellow);' : ''"></i>
        </button>
        <button class="button-large-small-text stretch-item" style="margin-left: 3px;" @click="tFav(user_slug, playerRec.id, !playerFavs.includes(playerRec.id))">
          {{ playerFavs.includes(playerRec.id) ? 'Un-Favorite ' : 'Add Favorite ' }}
          <i class="fa-solid fa-heart" :style="playerFavs.includes(playerRec.id) ? 'color: var(--delete-cancel-color);' : ''"></i>
        </button>
      </div>

      <div class="normal-heading stretch-item">{{ playerRec.headline }}</div>
      <div class="large-p stretch-item">{{ playerRec.summary }}</div>

      <div class="playerStats">
        <div class="normal-heading stretch-item" v-if="playerRec.youtube || playerRec.facebook || playerRec.instagram || playerRec.twitter || playerRec.medium" style="margin-top: 8px; margin-bottom: 8px; ">My Social Media Pages</div>
        <div class="normal-heading stretch-item">
          <span v-if="playerRec.youtube" class="playerSocial">
            <a :href="playerRec.youtube" target="_blank">
              <i class="fa-brands fa-youtube"></i>
            </a>
          </span>
          <span v-if="playerRec.facebook" class="playerSocial">
            <a :href="playerRec.facebook" target="_blank">
              <i class="fa-brands fa-facebook-square"></i>
            </a>
          </span>
          <span v-if="playerRec.instagram" class="playerSocial">
            <a :href="playerRec.instagram" target="_blank">
              <i class="fa-brands fa-instagram-square"></i>
            </a>
          </span>
          <span v-if="playerRec.twitter" class="playerSocial">
            <a :href="playerRec.twitter" target="_blank">
              <i class="fa-brands fa-twitter"></i>
            </a>
          </span>
          <span v-if="playerRec.medium" class="playerSocial">
            <a :href="playerRec.medium" target="_blank">
              <i class="fa-brands fa-medium"></i>
            </a>
          </span>
        </div>
        <div class="eventFormBlock">
          <div class="profileStatCard">
            <h2>Stats for {{ playerRec.playerName }}</h2>
            <hr />
            <div class="grid-container">
              <div class="profileStatCardStat">Points earned</div>
              <div class="profileStatCardValue">{{ playerRec.points }}</div>
              <div class="profileStatCardStat">Matches played</div>
              <div class="profileStatCardValue">{{ playerRec.wins + playerRec.losses }}</div>
              <div class="profileStatCardStat">Wins</div>
              <div class="profileStatCardValue">{{ playerRec.wins }}</div>
              <div class="profileStatCardStat">Losses</div>
              <div class="profileStatCardValue">{{ playerRec.losses }}</div>
              <div class="profileStatCardStat">Win%</div>
              <div class="profileStatCardValue">{{ (playerRec.wins + playerRec.losses > 0 ? parseFloat((playerRec.wins / (playerRec.wins + playerRec.losses) * 100).toFixed(1)) : 0) }}%</div>
              <div class="profileStatCardStat">Events hosted</div>
              <div class="profileStatCardValue">{{ playerRec.hosted ? playerRec.hosted : 0 }}</div>
              <div class="profileStatCardStat">Events attended</div>
              <div class="profileStatCardValue">{{ playerRec.attended ? playerRec.attended : 0 }}</div>
              <div class="profileStatCardStat">Players Drafted</div>
              <div class="profileStatCardValue">{{ playerRec.drafted ? playerRec.drafted : 0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="normal-spacer"></div>
</template>

<script>
import getPlayerByName from '@/composables/getPlayerByName'
import { format } from 'date-fns'
import slugify from 'slugify'
import { useRouter } from 'vue-router'
import po600by800 from '@/assets/Profile-Overlay600x800.png'
import useToggleFav from '@/composables/useToggleFav'
import useToggleFriend from '@/composables/useToggleFriend'
import { ref, onMounted, watch } from 'vue'
import usePlayerState from '@/composables/usePlayerState'
import Smash from '@/components/Smash.vue'
import phProfile from '@/assets/phProfile.png'
import { playerFriendsReady } from '@/composables/playerFriendsReady' // loading state waiter
import { playerFavoritesReady } from '@/composables/playerFavoritesReady' // loading state waiter
import { useStore } from 'vuex'

export default {
  name: 'view-player',
  props: ['pName'],
  name: 'messages',
  components: {
    Smash,
  },
  setup(props) {
    const store = useStore()
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always sc
    const { theme, playerData, waitForPlayerData } = usePlayerState()
    document.documentElement.setAttribute('data-theme', theme.value)
    const user_slug = ref('')
    const labelText = ref('Loading Player...')
    const labelSubtext = ref('almost done')
    const loadingData = ref(true)
    const phProf = ref(phProfile)
    const router = useRouter()
    // const { user_slug } = getUser()
    const { playerRec } = getPlayerByName(props.pName)
    const profileOverlay = po600by800
    const playerFriends = ref([])
    const playerFavs = ref([])
    const { toggleFav } = useToggleFav()
    const { toggleFriend } = useToggleFriend()

    watch(
      () => store.state.myFriendsRaw, // Watch this computed value
      (newFriends) => {
        playerFriends.value = newFriends
      },
      { immediate: true }
    )

    watch(
      () => store.state.myFavoritesRaw, // Watch this computed value
      (newFavs) => {
        playerFavs.value = newFavs
      },
      { immediate: true }
    )
    
    const tFav = async (user_slug, pId, addFav) => {
      toggleFav(user_slug, pId, addFav)
       }

    const tFriends = async (user_slug, pId, addFriend) => {
      toggleFriend(user_slug, pId, addFriend)
    }

    // drafted
    const draftDate = (dt) => {
      if (dt) {
        return format(new Date(dt.toDate()), 'MM/dd/yyyy')
      }
    }

    const handleGoThread = (from, err) => {
      // slugify then lookup to get name?
      if (from && !err) {
        let slug = null
        slug = slugify(from, {
          replacement: '-',
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        })
        router.push({ name: 'messagethread', params: { pName: slug } })
      }
    }

    onMounted(async () => {
      loadingData.value = true
      const success = await waitForPlayerData()
      if (success) {
        user_slug.value = playerData.value.id

        // playerFriendsReady loaded
        try {
          await playerFriendsReady(store) // Waits until myFriendsRawLoaded is true or throws an error after 10 seconds
          playerFriends.value = store.state.myFriendsRaw
        } catch (error) {
          console.error('Failed to wait for friends:', error)
        }

        // playerFavoritesReady loaded
        try {
          await playerFavoritesReady(store) // Waits until myFavoritesRawLoaded is true or throws an error after 10 seconds
          playerFavs.value = store.state.myFavoritesRaw
        } catch (error) {
          console.error('Failed to wait for favorites:', error)
        }

        loadingData.value = false
      }
    })

    return {
      playerRec,
      draftDate,
      handleGoThread,
      profileOverlay,
      user_slug,
      tFav,
      playerFavs,
      playerFriends,
      tFriends,
      labelText,
      labelSubtext,
      loadingData,
      phProf,
    }
  },
}
</script>