import { ref } from "vue";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "@/firebase/config";

export default function useToggleFav() {
    const error_ToggleFav = ref(null); // Track errors

    const toggleFav = async (user_slug, pId, addFav) => {
        if (user_slug === pId && addFav) {
            alert("Sorry, but you can't favorite yourself 💔.")
            return
        }

        try {
            await setDoc(
                doc(db, "playerFavorites", user_slug + pId),
                {
                    playerId: user_slug,
                    created: serverTimestamp(),
                    favorite: addFav,
                    favoriteId: pId,
                },
                { merge: true }
            );

            // if (addFav) {
            //     alert(`${pId} added to your favorites.`)
            // } else {
            //     alert(`${pId} removed from favorites.`)
            // }
        } catch (error) {
            console.error("Error toggling favorite:", error);
            error_ToggleFav.value = error.message;
        }
    };

    return {
        toggleFav,
        error_ToggleFav,
    };
}
