import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Welcome from '@/views/Welcome.vue'
import Reset from '@/views/Reset.vue'
import ViewPlayer from '@/views/ViewPlayer.vue'
import Alerts from '@/views/Alerts.vue'
import MenuItems from '@/views/MenuItems.vue'
import Messages from '@/views/Messages.vue'
import MessageThread from '@/views/MessageThread.vue'
import Upload from '@/views/Upload.vue'
import P404 from '@/views/P404.vue'
import { auth, db, functions } from '@/firebase/config'
import ViewPlayers from '@/views/ViewPlayers.vue'
import Landing from '@/views/Landing.vue'
import { getDoc, doc } from 'firebase/firestore' // Firestore imports
import getUser from "@/composables/getUser"
import addPlayerLocation from "@/composables/addPlayerLocation"
import Calendar from '@/views/Calendar.vue'
import Cookies from 'js-cookie';
import { httpsCallable } from 'firebase/functions'


const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    next({ name: 'landing', query: { redirect: to.fullPath } });
  } else {
    next();
  }
}

const signedIn = async (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    next();
  } else {
    if (to.name === 'registerWithReferral' && to.params.referralCode) {
      const { user_slug } = getUser();
      const referralCode = to.params.referralCode;
      const locationDocRef = doc(db, 'locations', referralCode);
      const locationDoc = await getDoc(locationDocRef);
      if (locationDoc.exists()) {
        if (confirm(`Add ${referralCode} to your favorite venue list?`)) {
          addPlayerLocation(user_slug.value, referralCode);
          next({ name: 'events', params: { locationId: referralCode } });
        } else {
          next({ name: 'home' });
        }
      } else {
        next({ name: 'home' });
      }
    } else {
      next({ name: 'home' });
    }
  }
}



const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: requireAuth
  },
  {
    path: '/playersetup',
    name: 'playerSetup',
    component: () => import(/* webpackChunkName: "PlayerSetup" */ '@/views/PlayerSetup.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
    // component: () => import(/* webpackChunkName: "Welcome" */ '@/views/Welcome.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/events/:locationId',
    name: 'events',
    beforeEnter: (to) => {
      return {
        name: 'CalendarLocation',
        params: { locationId: to.params.locationId }
      };
    }
  },
  // _v1_
  // {
  //   // create event called with date path
  //   path: '/events/:locationId',
  //   name: 'events',
  //   component: () => import(/* webpackChunkName: "Events" */ '@/views/Events.vue'),
  //   props: true,
  //   beforeEnter: requireAuth
  // },
  {
    path: '/landing',
    name: 'landing',
    props: true,
    component: Landing,
    beforeEnter: signedIn
  },
  {
    path: '/terms',
    name: 'terms',
    // component: Terms,
    component: () => import(/* webpackChunkName: "Terms" */ '@/views/Terms.vue'),
    beforeEnter: signedIn
  },
  {
    path: '/privacy',
    name: 'privacy',
    // component: Terms,
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/Privacy.vue'),
    beforeEnter: signedIn
  },
  {
    path: '/signin',
    name: 'signIn',
    props: true,
    // component: SignIn,
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/SignIn.vue'),
    beforeEnter: signedIn
  },
  {
    // register with manual referral code input
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '@/views/Register.vue'),
    beforeEnter: signedIn

  },
  {
    // register with automatic referral code input
    path: '/register/:referralCode',
    name: 'registerWithReferral',
    component: () => import(/* webpackChunkName: "Register" */ '@/views/Register.vue'),
    props: true,
    beforeEnter: signedIn
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    beforeEnter: signedIn

  },
  {
    // create event called with date / loc path
    path: '/createevent/:locId/:startDate/:startTime',
    name: 'createEventWithDateTime',
    component: () => import(/* webpackChunkName: "CreateEvent" */ '@/views/CreateEvent.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    // create event called with date / loc path
    path: '/createevent/:startDate/:locId',
    name: 'createEventWithDate',
    // component: CreateEvent,
    component: () => import(/* webpackChunkName: "CreateEvent" */ '@/views/CreateEvent.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    // create event called with NO date path
    path: '/createevent/',
    name: 'createEvent',
    // component: CreateEvent,
    component: () => import(/* webpackChunkName: "CreateEvent" */ '@/views/CreateEvent.vue'),
    beforeEnter: requireAuth
  },
  {
    // view edit event called with id
    path: '/vieweditevent/:evId',
    name: 'viewEditEventWithId',
    // component: ViewEditEvent,
    component: () => import(/* webpackChunkName: "ViewEditEvent" */ '@/views/ViewEditEvent.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    // view edit league called with id
    path: '/vieweditleague/:leagueSeriesId',
    name: 'vieweditleague',
    component: () => import(/* webpackChunkName: "ViewEditLeague" */ '@/views/ViewEditLeague.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    // players for location
    path: '/players/:locId',
    name: 'players',
    component: ViewPlayers,
    props: true,
    beforeEnter: requireAuth
  },
  {
    // players for location with admin filter
    path: '/players/:locId/:showAdmin',
    name: 'playersAdmin',
    component: ViewPlayers,
    props: true,
    beforeEnter: requireAuth
  },
  {
    // view edit event called with id
    path: '/editclub/:clubId',
    name: 'editclub',
    // component: ViewEditEvent,
    component: () => import(/* webpackChunkName: "EditClub" */ '@/views/EditClub.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/player',
    name: 'player',
    // component: Player,
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/media',
    name: 'media',
    component: () => import(/* webpackChunkName: "Media" */ '@/views/ViewMedia.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/poppi',
    name: 'poppi',
    // component: Poppi,
    component: () => import(/* webpackChunkName: "Poppi" */ '@/views/ViewPoppi.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/player/:locId',
    name: 'playerSetHome',
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/player/:locId/:addFavLoc',
    name: 'playerSetFav',
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/player/settings/:scrollTarget?',
    name: 'playerSettings',
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/viewplayer/:pName',
    name: 'viewplayer',
    component: ViewPlayer,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/venues',
    name: 'venues',
    props: true,
    component: () => import(/* webpackChunkName: "Venues" */ '@/views/Venues.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/venues/:locId',
    name: 'venuesByLocation',
    props: true,
    component: () => import(/* webpackChunkName: "Venues" */ '@/views/Venues.vue'),
    beforeEnter: requireAuth
  },
  // {
  //   path: '/locations',
  //   name: 'locations',
  //   props: true,
  //   // component: Locations,
  //   component: () => import(/* webpackChunkName: "Locations" */ '@/views/Locations.vue'),
  //   beforeEnter: requireAuth
  // },
  {
    path: '/leagues/:locId',
    name: 'leagues',
    props: true,
    component: () => import(/* webpackChunkName: "Leagues" */ '@/views/ViewLeagues.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/locationedit/:lId',
    name: 'locationEdit',
    component: () => import(/* webpackChunkName: "Locations" */ '@/views/LocationEdit.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "Support" */ '@/views/Support.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: Alerts,
    beforeEnter: requireAuth
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    beforeEnter: requireAuth
  },
  {
    path: '/messagethread/:pName',
    name: 'messagethread',
    component: MessageThread,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/viewplayer/:pName',
    name: 'viewplayer',
    component: ViewPlayer,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/upload/:targetId',
    name: 'upload',
    component: Upload,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/menu',
    name: 'menuitems',
    component: MenuItems,
    beforeEnter: requireAuth
  },
  {
    path: '/venuemenu',
    name: 'venuemenu',
    component: () => import(/* webpackChunkName: "VenueMenue" */ '@/views/VenueMenu.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/venuelist',
    name: 'venuelist',
    component: () => import(/* webpackChunkName: "VenueList" */ '@/views/VenueList.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/venuedetail/:locationId',
    name: 'VenueDetail',
    component: () => import(/* webpackChunkName: "VenueDetail" */ '@/views/VenueDetail.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/myeventsmenu',
    name: 'myeventsmenu',
    component: () => import(/* webpackChunkName: "VenueMenue" */ '@/views/MyEventsMenu.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/calendar/:locationId',
    name: 'CalendarLocation',
    component: Calendar,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/eventday/:date',
    name: 'EventDay',
    component: () => import(/* webpackChunkName: "EventDay" */ '@/views/EventDay.vue'),  // Lazy load with chunk name
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/eventday/:date/:locationId',
    name: 'EventDayLocationId',
    component: () => import(/* webpackChunkName: "EventDayLocationId" */ '@/views/EventDay.vue'),  // Lazy load with chunk name
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/eventday',
    name: 'EventDayNoDate',
    component: () => import(/* webpackChunkName: "EventDayNoDate" */ '@/views/EventDay.vue'),  // Lazy load with chunk name
    beforeEnter: requireAuth,
  },
  {
    path: '/myevents',
    name: 'MyEvents',
    component: () => import(/* webpackChunkName: "MyEvents" */ '@/views/MyEvents.vue'),  // Lazy load with chunk name
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/myhistory',
    name: 'MyHistory',
    component: () => import(/* webpackChunkName: "myhistory" */ '@/views/MyHistory.vue'),  // Lazy load with chunk name
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/list',
    name: 'EventList',
    component: () => import(/* webpackChunkName: "MyEvents" */ '@/views/EventList.vue'),  // Lazy load with chunk name
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: '/signout',
    name: 'signout',
    component: () => import(/* webpackChunkName: "SignOut" */ '@/views/SignOut.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'P404',
    component: P404

  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth', // Optional: enables smooth scrolling
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  let user = auth.currentUser;
  if (to.path.startsWith('/vieweditevent/') && !user) {
    console.log('Tried to link to event when not logged in.  Saving link for later recall:', to.fullPath);
    Cookies.set('pendingEvent', to.fullPath, { expires: 1 }); 
    const eventId = to.path.split('/vieweditevent/')[1];

    if (eventId) {
      console.log('Extracted eventId:', eventId);
      Cookies.set('pendingEventId', eventId, { expires: 1 }); // Store eventId separately if needed

      const getEventLocation = httpsCallable(functions, 'validations-getEventLocation');

      getEventLocation({ eventId })
        .then((response) => {
          if (response.data.success && response.data.location) {
            console.log('✅ Location ID:', response.data.location);
            Cookies.set('pendingLocation', response.data.location, { expires: 1 });
          } else {
            console.warn('⚠️ No location found for this event.');
          }
        })
        .catch((error) => {
          console.error('❌ Error getting location associated with this event:', error);
        });
    }

  }
  next();
});

// Handle chunk load errors
router.onError((error) => {
  if (error.name === "ChunkLoadError") {
    console.error("Chunk load error detected. Reloading...");
    window.location.reload();
  }
});

export default router;
