<template>
  <section class="feature-list-grid-container">
    <div class="feature-list-grid-cell">
      <!-- Add a wrapper div for centering the list -->
      <div class="feature-list-wrapper">
        <ul class="feature-list-ul">
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("game_signups") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("roundRobin") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("tournaments") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("scorekeeping") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("awards") }}
          </li>
        </ul>
      </div>
    </div>
    <div class="feature-list-grid-cell">
      <!-- Add a wrapper div for centering the list -->
      <div class="feature-list-wrapper">
        <ul class="feature-list-ul">
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("eventsPrivate") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("messaging") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("personalStats") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("notifications") }}
          </li>
          <li class="feature-list-item">
            <i class="fa-solid fa-check-square feature-list-icon"></i>
            {{ t("social") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'FeatureList',
  setup() {
    const { t } = useI18n()
    return {
      t, // directly using t in the template for simplicity.
    }
  },
}
</script>

<style scoped>
.feature-list-grid-container {
  display: flex;
  justify-content: space-between;
  gap: var(--grid-gap);
  padding-top: var(--padding-tiny);
  padding-bottom: var(--padding-regular);
  width: 90%; /* Ensure the container takes up 90% of its parent container */
  margin: 0 auto; /* Center the container horizontally */
}

.feature-list-grid-cell {
  flex: 1;
  background-color: var(--button-hover-color);
  padding: 20px;
  border-radius: 15px;
  max-width: 48%; /* Keep each grid cell under half of the container width */
  display: flex;
  align-items: center; /* Center the content vertically within the grid cell */
  justify-content: center; /* Center the content horizontally within the grid cell */
}

.feature-list-wrapper {
  display: flex; /* Use flex to align the container properly */
  flex-direction: column; /* Keep the items vertically stacked */
  align-items: center; /* Align items to the center of the wrapper */
  justify-content: center; /* Vertically center if needed */
  width: 95%; /* Use a percentage width to keep the text from wrapping while maintaining balance */
  min-width: 30%; /* Prevent it from shrinking too small */
  max-width: 100%; /* Avoid overflowing from the grid cell */
  margin: 0 auto; /* Center the wrapper horizontally within the grid cell */
}

.feature-list-ul {
  list-style-type: none; /* Remove default bullet points */
  padding-left: 0;
}

.feature-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: var(--font-size-subheading);
  color: var(--button-text-color); /* Text color */
}

.feature-list-icon {
  margin-right: 10px; /* Add space between the icon and the text */
  color: var(--button-text-color); /* Adjust the color to match your branding */
}

/* Media queries for different screen sizes */
@media screen and (max-width: 599px) {
  .feature-list-grid-container {
    padding: 10px;
  }
  .feature-list-grid-cell {
    padding: 10px;
  }
  .feature-list-item {
    /* font-size: 18px; */
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 499px) {
  .feature-list-grid-container {
     padding: 8px;
  }
  .feature-list-grid-cell {
    padding: 6px;
  }

  .feature-list-item {
    /* font-size: 15px; */
    margin-bottom: 7px;
  }
}

@media screen and (max-width: 399px) {
  .feature-list-grid-container {
      padding: 4px;
  }
  .feature-list-grid-cell {
    padding: 4px;
  }
}
</style>
