import { ref } from 'vue'
import { db } from "@/firebase/config";
import { doc, getDoc } from "firebase/firestore";
const eventRec = ref([])

const getEvent = async (eId) => {
    // console.log('EID:', eId)
    const docRef = doc(db, "events", eId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        // console.log("Event data:", docSnap.data());
        eventRec.value = docSnap.data()
    } else {
        // doc.data() will be undefined in this case
        console.log("Event not found");
    }
    return eventRec
}
export default getEvent

