<template>
  <div class="menu-items">
    <!-- <MenuButton icon="fa-solid fa-user" label="View Profile" route="/profile" img-src="/path/to/profile-picture.jpg" /> -->
    <MenuButton icon="fa-solid fa-house" :label="labelHome" :aria-label="labelHome" route="/" />
    <MenuButton icon="fa-solid fa-user" :label="labelProfile" :aria-label="labelProfile" route="/player" />
    <MenuButton icon="fa-solid fa-calendar" :label="labelEventCalendar" :aria-label="labelEventCalendar" route="/calendar" />
    <MenuButton icon="fa-solid fa-list" :label="labelEventList" :aria-label="labelEventList" route="/list" />
    <MenuButton icon="fa-solid fa-pickleball" label="My Events" aria-label="My Events" route="/myeventsmenu" />
    <MenuButton icon="fa-solid fa-map-location-dot" :label="labelMyVenues" :aria-label="labelMyVenues" route="/venuemenu" />
    <MenuButton icon="fa-solid fa-people-group" :label="labelMyCommunity" :aria-label="labelMyCommunity" :route="myCommunityLink" />
    <MenuButton icon="fa-solid fa-messages" :label="labelMessages" :notice="msgCount" :aria-label="labelMessages" route="/messages" />
    <MenuButton icon="fa-solid fa-face-smiling-hands" :label="labelSupport" :aria-label="labelSupport" route="/support" />
    <MenuButton icon="fa-solid fa-power-off" :label="labelSignOut" :aria-label="labelSignOut" route="/signout" />
  </div>
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'
import { useI18n } from 'vue-i18n'
import usePlayerState from '@/composables/usePlayerState'
import { ref, watch, onMounted } from 'vue'
import getMessageCount from '@/composables/getMessageCount'

export default {
  name: 'MenuItems',
  components: {
    MenuButton,
  },
  setup() {
    const { t } = useI18n()
    const user_slug = ref('')
    const homeLoc = ref('')
    const myCommunityLink = ref('')
    const loadingData = ref(false)
    const { playerData, waitForPlayerData } = usePlayerState()
    const msgCount = ref(0) // Initialize msgCount to 0
    // Initialize isMenuOpen based on the current route when mounted
    onMounted(async () => {
      loadingData.value = true
      const success = await waitForPlayerData()
      loadingData.value = false
      if (success) {
        user_slug.value = playerData.value.id
        homeLoc.value = playerData.value.homeLocation
        myCommunityLink.value = '/players/' + playerData.value.homeLocation
      }
    })

    // Watch user_slug and run getMessageCount when it has a value
    watch(
      user_slug,
      (newSlug) => {
        if (newSlug) {
          const { msgCount: newMsgCount } = getMessageCount(newSlug)
          watch(
            newMsgCount,
            (value) => {
              if (value !== null) {
                msgCount.value = value
              }
            },
            { immediate: true } // Ensure it runs immediately to capture the current count
          )
        }
      },
      { immediate: false }
    )

    return {
      labelProfile: t('profile'), // Retrieves "Player Profile" or equivalent translation
      labelSignOut: t('signOut'), // Retrieves "Sign Out" or equivalent translation
      labelEventCalendar: t('eventCalendar'), // Retrieves "Event Calendar"
      labelEventList: t('eventList'), // Retrieves "Event List"
      labelMyVenues: t('myVenues'), // Retrieves "My Venues"
      labelMessages: t('messages'), // Retrieves "Messages"
      labelSupport: t('support'), // Retrieves "Support"
      msgCount,
      labelMyCommunity: t('myCommunity'),
      labelHome: t('home'),
      homeLoc,
      myCommunityLink,
    }
  },
}
</script>
  
  <style scoped>
.menu-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--grid-gap-large);
  padding: var(--padding-regular);
  align-items: center;
  justify-items: center;
  width: 96%;
}
</style>
  