<template>
  <div class="menu-alert" style="padding-left: var(--padding-small);" @click="$emit('click')">
    <i class="fa-solid fa-bars" aria-label="Menu"></i>
    <span v-if="msgCount > 0" class="notification-badge" aria-label="Unread notifications">{{ msgCount }}</span>
  </div>
  <!-- <span v-if="msgCount">{{ msgCount }}</span> -->
</template>
  
<script>
import getMessageCount from '@/composables/getMessageCount'
import {onUnmounted } from 'vue'
export default {
  name: 'MenuHamburger',
  props: {
    user_slug: String,
  },
  emits: ['click'], // Declare the 'click' event
  setup(props) {
    const { msgCount } = getMessageCount(props.user_slug)
    if(msgCount.value > 99) {
      msgCount.value = 99
    }
    onUnmounted(() => {
        // No cleanup needed, but this resolves the warning
    })

    return {
      msgCount,
    }
  },
}
</script>
  
  