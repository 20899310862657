/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('An app update has been detected.')
    },
    updated(registration) {
      console.log('New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA');

      const lastUpdatePrompt = localStorage.getItem('lastUpdatePrompt');
      const now = Date.now();

      // Only show the confirm box if it hasn't been shown in the last 10 minutes
      if (!lastUpdatePrompt || now - lastUpdatePrompt > 10 * 60 * 1000) {
        localStorage.setItem('lastUpdatePrompt', now);

        // alert('🔥UPGRADE COMPLETE!!🚀');
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        // window.location.reload();
      }
    },
    offline() {
      alert('There seems to be an issue with your Interwebs, please check connection.');
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}