<template>
  <section class="slogan-container">
    <div class="slogan">{{ t("slogan1") }}</div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'Slogan',
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
}
</script>

<style scoped>
.slogan-container {
  text-align: center;
  width: 90%;
}

.slogan {
  font-size: var(--font-size-heading);
  /* font-style: italic; */
  font-weight: 500;
  color: var(--text-color);
  margin-top: var(--margin-regular);
}
</style>