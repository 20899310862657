<template>
  <div class="menu-player">
    <div class="player-name">{{ user_name }}</div>
    <div class="player-details">
      <span class="medalLink" @click="clickMedal('gold')">🥇{{ playerData?.medalGold || 0 }}</span>
      <span v-if="false" class="medal-plus">+</span>
      <span class="medalLink" @click="clickMedal('silver')">🥈{{ playerData?.medalSilver || 0 }}</span>
      <span v-if="false" class="medal-plus">+</span>
      <span class="medalLink" @click="clickMedal('bronze')">🥉{{ playerData?.medalBronze || 0 }}</span>
      <span v-if="false" class="medal-plus">+</span>
      👑{{ playerData?.adminLocations || 0 }}
      <span v-if="false" class="medal-plus">+</span>
      💎{{ playerData?.diamonds || 0 }}
      <span v-if="false" class="medal-plus">+</span>
    </div>
  </div>
</template>
  
  <script>
import { useRouter } from 'vue-router'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/firebase/config'
import { format } from 'date-fns'

export default {
  name: 'MenuPlayer',
  props: {
    user_name: String,
    playerData: Object,
  },
  setup() {
    const router = useRouter()
    httpsCallable(functions, 'players-playerUpdateMedalCount')({ today: 'wakeUp' })
    const runUpdateMedals = async () => {
      const today = format(new Date(), 'yyyy-MM-dd')
      const updateMedals = httpsCallable(functions, 'players-playerUpdateMedalCount')
      try {
        const response = await updateMedals({ today })
        // console.log('✅ Medal count updated:', response.data)
      } catch (error) {
        // console.error('❌ Error updating medals:', error)
      }
    }
    runUpdateMedals()

    const clickMedal = (medal) => {
      router.push('/myhistory?' + medal + '=true')
    }
    return {
      clickMedal,
    }
  },
}
// this is prolly gonna need it's own listener for rewards like hamburger->getMessageCount but just add to Vuex and pull this through usePlayerState
</script>
  
  <style scoped>
.menu-player {
  display: flex;
  flex-direction: column;
  /* width: 50%; */
}
.player-name {
  font-size: var(--font-size-title);
  font-weight: bold;
  color: var(--subtle-text-color);
  text-align: center;
}
.player-details {
  display: flex;
  background-color: var(--background-color-verytrans);
  padding-left: var(--padding-tiny);
  padding-right: var(--padding-tiny);
  border-radius: var(--radius-small);
  justify-content: space-between;
  font-size: var(--font-size-subheading);
  color: var(--subtle-text-color);
}

.medal-plus {
  font-size: var(--font-size-regular);
}
</style>
  