<template>
  <Smash :isVisible="loadingData" :labelText="labelText" :labelSubtext="labelSubtext" />
  <ShowMessages v-if="!loadingData && user_slug" :user_slug="user_slug" />
</template>

<script>
// import SendAlertForm from '@/components/SendAlertForm.vue'
import ShowMessages from '@/components/ShowMessages.vue'
import Smash from '@/components/Smash.vue'
import { ref, onMounted } from 'vue'
import usePlayerState from '@/composables/usePlayerState'

export default {
  name: 'messages',
  components: { 
    // SendAlertForm, 
    ShowMessages, 
    Smash 
  },
  setup() {
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always scroll to top on load
    const labelText = ref('Loading...')
    const labelSubtext = ref('almost done')
    const loadingData = ref(true)

    const { theme, playerData, waitForPlayerData } = usePlayerState()
    document.documentElement.setAttribute('data-theme', theme.value)
    const user_slug = ref('')

    onMounted(async () => {
      loadingData.value = true
      const success = await waitForPlayerData()
      loadingData.value = false
      if (success) {
        user_slug.value = playerData.value.id
      }
    })

    return {
      labelText,
      labelSubtext,
      user_slug,
      loadingData,
    }
  },
}
</script>

<style></style>