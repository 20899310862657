<template>
  <Smash :isVisible="loadingData" :labelText="labelText" :labelSubtext="labelSubtext" />

  <div class="outer-wrapper">
    <div v-if="imageData" class="venue-image-container">
      <cropper :class="dispMode" :src="img_src" :stencil-props="{ aspectRatio: cropRatio }" ref="cropperRef" />
    </div>
    <!-- Placeholder for when no image is selected -->
    <div v-else class="venue-image-container" @click="clickPhoto">
      <img :src="img_ph" alt="Venue Image" :class="dispMode" />
    </div>
  </div>
  <div v-if="uploading">Uploading... {{ progress }}%</div>
  <div v-if="!imageData" class="outer-wrapper">
    <button class="button-large-small-text" @click="clickPhoto">Click to select photo</button>
    <input type="file" ref="input_file" style="display: none" @change="previewImage" accept=".png, .jpeg, .jpg" />
  </div>
  <button v-if="imageData" class="button-large-small-text" @click="uploadImageToStorage">Crop and Upload</button>
  <button class="button-large-small-text" @click="goBack">Cancel</button>
</template>

<script>
import { ref } from 'vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { useImageUploader } from '@/composables/useImageUploader'
import updatePlayerPhoto from '@/composables/updatePlayerPhoto' // Ensure this import is correct
import updateLocationPhoto from '@/composables/updateLocationPhoto' // Ensure this import is correct
import router from '@/router'
import Smash from '@/components/Smash.vue'
import phImg1200by600 from '@/assets/1200x600.png'
import phImg600by800 from '@/assets/600x800.png'

export default {
  name: 'ImageUpload',
  props: {
    cropRatio: {
      type: Number,
      default: 34 / 40,
    },
    cropWidth: {
      type: Number,
      default: 340, // Default width for cropped image
    },
    cropHeight: {
      type: Number,
      default: 400, // Default height for cropped image
    },
    storagePath: {
      type: String,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
      validator: (value) => ['player', 'location'].includes(value), // Allow only valid targets
    },
  },

  components: {
    Cropper,
    Smash,
  },
  setup(props) {
    const { progress, uploading, uploadImage } = useImageUploader()
    const imageData = ref(null)
    const img_src = ref(null)
    const cropperRef = ref(null)
    const input_file = ref(null) // Define the ref for the file input
    const loadingData = ref(false)
    const labelText = ref('Loading Image...')
    const labelSubtext = ref('almost done')
    const displayTip = ref(true)
    const dispMode = ref('venue-image')
    const img_ph = ref('')

    if (props.cropWidth / props.cropHeight == 2) {
      console.log('2')
      img_ph.value = (phImg1200by600)
    } else if (props.cropWidth / props.cropHeight == .75) {
      console.log('0.75')
      img_ph.value = (phImg600by800)
      dispMode.value = 'venue-imagePort'
    }

    if (props.cropHeight < 300) {
      displayTip.value = false
    }
    const clickPhoto = () => {
      input_file.value.click() // Trigger the file input
    }
    const goBack = () => {
      router.back()
    }
    const previewImage = (event) => {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            // Get the original dimensions of the image
            const originalWidth = img.width
            const originalHeight = img.height

            // Calculate the scaling factor to fit within cropWidth and cropHeight
            const widthScale = props.cropWidth / originalWidth
            const heightScale = props.cropHeight / originalHeight
            const scale = Math.min(widthScale, heightScale) // Use the smaller scale to fit within bounds

            // Calculate the new dimensions while preserving the aspect ratio
            const resizedWidth = originalWidth * scale
            const resizedHeight = originalHeight * scale

            // Create a canvas for resizing
            const resizedCanvas = document.createElement('canvas')
            resizedCanvas.width = resizedWidth
            resizedCanvas.height = resizedHeight

            // Draw the scaled image onto the canvas
            const context = resizedCanvas.getContext('2d')
            context.drawImage(img, 0, 0, resizedWidth, resizedHeight)

            // Set the resized image as the cropper source
            img_src.value = resizedCanvas.toDataURL()
            imageData.value = file // Store the original file for reference if needed
          }
        }
        reader.readAsDataURL(file)
      }
    }

    const uploadImageToStorage = async () => {
      loadingData.value = true
      try {
        if (!cropperRef.value) {
          throw new Error('Cropper is not initialized')
        }

        // Get the cropped canvas from the cropper
        const { canvas } = cropperRef.value.getResult()

        // Create a new canvas for resizing to the desired resolution
        const resizedCanvas = document.createElement('canvas')
        resizedCanvas.width = props.cropWidth
        resizedCanvas.height = props.cropHeight

        // Draw the cropped image onto the resized canvas
        const context = resizedCanvas.getContext('2d')
        context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resizedCanvas.width, resizedCanvas.height)

        // Convert the resized canvas to a Blob
        const blob = await new Promise((resolve) => resizedCanvas.toBlob(resolve))

        // Upload the resized image
        const url = await uploadImage(blob, props.storagePath)
        console.log('Image uploaded successfully:', url)

        // Determine which Firestore update function to call
        switch (props.target) {
          case 'player':
            await updatePlayerPhoto(props.targetId, url) // Use the existing player update logic
            break
          case 'location':
            await updateLocationPhoto(props.targetId, url) // Add logic for updating location photos
            break
          default:
            throw new Error(`Unsupported target: ${props.target}`)
        }

        console.log(`Photo updated for ${props.target} with ID ${props.targetId}`)
        loadingData.value = false

        // Navigate back to the appropriate page
        router.back()
      } catch (error) {
        console.error('Upload failed:', error)
        loadingData.value = false
      }
    }

    return {
      progress,
      uploading,
      img_src,
      imageData,
      cropperRef,
      input_file,
      clickPhoto,
      previewImage,
      uploadImageToStorage,
      goBack,
      loadingData,
      labelText,
      labelSubtext,
      displayTip,
      img_ph,
      dispMode,
    }
  },
}
</script>
<style scoped>
.placeholder {
  display: flex;
  align-items: center; /* Center placeholder contents vertically */
  justify-content: center; /* Center placeholder contents horizontally */
  width: 90%; /* Default width to ensure scaling within container */
  max-width: 96%; /* Prevent overflow */
  aspect-ratio: var(--aspect-ratio); /* Maintain aspect ratio dynamically */
  background-color: var(--button-disabled-text-color); /* Grey placeholder background */
  display: flex;
  border: 3px dashed var(--button-disabled-color); /* Dashed border for visual effect */
  border-radius: var(--radius-regular); /* Rounded corners */
  overflow: hidden; /* Prevent any child overflow */
}

.placeholder-content {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;
}

.placeholder-icon {
  font-size: 2.5rem;
}

.placeholder-text {
  font-size: var(--font-size-heading);
  color: var(--button-disabled-color);
  font-weight: 500;
}

.tip-text {
  font-size: var(--font-size-subheading);
  font-weight: 300;
  color: var(--button-disabled-color);
  width: 90%;
}
</style>
