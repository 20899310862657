<template>
    <div class="landing-logo" @click="handleLanding">
        <img src="@/assets/BaseLogo-ThumbCircle.png" alt="Pickled.App" />
    </div>
    <div class="landing-heading">{{ t('welcome') }}</div>
    <div class="landing-subheading">{{ t('theBestPlayground') }}&trade;</div>
    <div class="landing-founded">Since 2022</div>

</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
export default {
    name: 'LogoHeading',
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        const handleLanding = () => {
            router.push("/landing");
        };

        return {
            t,
            handleLanding
        };
    }
};
</script>
<style scoped>
.landing-heading {
    font-size: var(--font-size-heading);
    font-weight: 500;
    color: var(--text-color);
    margin: var(--margin-tiny);
}

.landing-subheading {
    font-size: var(--font-size-subheading);
    font-weight: 300;
    color: var(--text-color);
    margin-top: 0px;
    /* margin-bottom: var(--margin-regular); */
}

.landing-founded {
    font-size: var(--font-size-regular);
    /* font-family: monospace; */
    font-weight: 300;
    color: var(--button-disabled-text-color);
    margin-top: 5px;
    /* margin-bottom: var(--margin-tiny); */
}

.landing-logo {
    display: flex;
    padding-top: 8px;
    margin-top: var(--margin-regular);
    flex: 0;
}

.landing-logo img {
    margin: auto;
    width: 45%;
}


</style>
