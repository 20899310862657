import { ref, onUnmounted, getCurrentInstance } from 'vue'
import { db } from '@/firebase/config'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { format, addDays } from 'date-fns'


export default function useEventCountsLocation(locationId) {
  const eventCounts = ref([]) // Changed from eventCountsLocation to eventCounts

  let eventsUnsub = null

  const fetchEventCountsLocation = () => {
    const today = format(new Date(), 'yyyy-MM-dd')
    const futureDate = format(addDays(new Date(), 60), 'yyyy-MM-dd')

    const eventsRef = collection(db, 'events')
    const eventsQuery = query(
      eventsRef,
      where('startDate', '>=', today),
      where('startDate', '<=', futureDate),
      where('invite', '==', false),
      where('location', '==', locationId)
    )

    if (eventsUnsub) eventsUnsub()

    eventsUnsub = onSnapshot(eventsQuery, (eventSnapshot) => {
      const counts = {}

      eventSnapshot.forEach((doc) => {
        const { startDate, location, deleted } = doc.data()
        if (deleted === true) return

        const key = `${startDate}_${location}`
        if (!counts[key]) {
          counts[key] = { date: startDate, locationId: location, eventCount: 0 }
        }
        counts[key].eventCount += 1
      })

      eventCounts.value = Object.values(counts)
    })
  }

  fetchEventCountsLocation()

  if (getCurrentInstance()) {
    onUnmounted(() => {
      if (eventsUnsub) eventsUnsub()
    })
  }

  return { eventCounts }
}
