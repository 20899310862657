<template>
  <div v-if="docMsgLast" @submit.prevent class="outer-wrapper">
    <div v-if="errMsgLast" class="input-block-err">{{ errMsgLast }}</div>
    <div class="normal-subheading">Tap a thread below or enter player name</div>
    <PlayerPicker @select-player="(player) => handleGoThread(player)" :user_slug="user_slug" />
    <div v-if="visibleThreads.length" class="message-list">
      <div v-for="doc in visibleThreads" :key="doc.id" :class="doc.read ? 'message-item' : 'message-unread-item'" @click="handleGoThread(doc.from)">
        <img :src="doc.channelPhotoURL ? doc.channelPhotoURL : playerPhotoURL" alt="Img" class="message-thumbnail" />
        <div class="message-content">
          <div class="message-header">
            <span class="message-player">@{{ doc.from }}</span>
            <span class="message-timestamp">{{ doc.created }}</span>
          </div>
          <div class="message-preview">{{ doc.message.substring(0, 100) }}</div>
        </div>
      </div>

      <!-- Show More Button -->
    </div>
    <div class="normal-spacer"></div>
    <button v-if="visibleThreads.length && hasMore" @click="loadMoreThreads" class="button-large-small-text">Show More</button>
  </div>
</template>

<script>
import getMessageLast from '@/composables/getMessageLast'
import { formatDistanceToNow } from 'date-fns'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators'
import slugify from 'slugify'
import { ref, computed, onMounted } from 'vue'
import PlayerPicker from '@/components/PlayerPicker.vue'
export default {
  name: 'show-messages-summary',
  components: {
    PlayerPicker,
  },
  props: {
    user_slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always scroll to top on load
    const v$ = useVuelidate()

    const slug = ref('')
    const router = useRouter()

    const threadsPerPage = 5 // Number of threads to display per page
    const currentPage = ref(1) // Tracks the current page

    // Computed property for the visible threads (slice of the full list)
    const visibleThreads = computed(() => {
      return documentsFormatted.value.slice(0, currentPage.value * threadsPerPage)
    })
    // Check if there are more threads to display
    const hasMore = computed(() => {
      return documentsFormatted.value.length > visibleThreads.value.length
      // return docMsgLast.value.length > visibleThreads.value.length
    })

    // Function to load more threads by increasing the page count
    const loadMoreThreads = () => {
      currentPage.value += 1
    }

    // need to get the player photo here.
    const playerPhotoURL = ref('https://firebasestorage.googleapis.com/v0/b/pickled-sandbox.appspot.com/o/images%2FPurplePickledLogo-Thumb.png?alt=media&token=4cff4290-084b-4cce-b513-1ab6e7a6f832')
    // get summary of all channels
    const { errMsgLast, docMsgLast } = getMessageLast(props.user_slug)
    // console.log('docMsgLast', docMsgLast)
    const documentsFormatted = computed(() => {
      return docMsgLast.value
        ? docMsgLast.value.map((doc) => {
            let time = formatDistanceToNow(doc.created.toDate())
            return { ...doc, created: time }
          })
        : []
    })

    const handleGoThread = (from, err) => {
      // slugify then lookup to get name?
      if (from && !err) {
        let slug = null
        slug = slugify(from, {
          replacement: '-',
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        })
        router.push({ name: 'messagethread', params: { pName: slug } })
      }
    }
    return {
      errMsgLast,
      docMsgLast,
      documentsFormatted,
      playerPhotoURL,
      handleGoThread,
      slug,
      v$,
      visibleThreads,
      hasMore,
      loadMoreThreads,
    }
  },
}
</script>

<style>
.message-list {
  display: flex;
  flex-direction: column;
  gap: var(--margin-small);
  width: var(--width-default);
}

.message-item {
  display: flex;
  align-items: center;
  padding: var(--padding-tiny);
  border-radius: var(--radius-regular);
  background-color: var(--button-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.message-unread-item {
  display: flex;
  align-items: center;
  padding: var(--padding-tiny);
  border-radius: var(--radius-regular);
  background-color: var(--button-hover-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.message-item:hover {
  background-color: var(--button-hover-color-semitrans);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.message-unread-item:hover {
  background-color: var(--button-hover-color-semitrans);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.message-thumbnail {
  width: var(--photo-thumb-width-small);
  height: var(--photo-thumb-height-small);
  border-radius: var(--radius-small);
  object-fit: cover;
  margin-right: var(--margin-small);
}

.message-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.message-header {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-heading);
  font-weight: 500;
  margin-bottom: var(--margin-tiny);
}

.message-player {
  color: var(--button-text-color);
  font-weight: 500;
}

.message-timestamp {
  color: var(--background-color-semitrans);
  font-size: var(--font-size-subheading);
}

.message-preview {
  color: var(--button-text-color);
  font-size: var(--font-size-subheading);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  white-space: nowrap;
}
</style>