<template>
  <Smash :isVisible="loadingData || clearingAlerts" :labelText="labelText" :labelSubtext="labelSubtext" />
  <div class="normal-heading">🔔 Alerts 🔔</div>
  <div class="inner-wrapper">
    <button @click="handleAllRead" class="button-large-small-text stretch-item">
      <i class="fa-solid fa-file-check"></i>
      Mark All Read
    </button>

    <div v-if="documents && documents.length" class="inner-wrapper">
      <div v-for="doc in displayedAlerts" :key="doc.id" class="alertWrapper">
        <span :class="doc.read ? 'alertTimestampRead' : 'alertTimestamp'">{{ doc.created }}</span>
        <span v-if="doc.eventId" :class="doc.read ? 'alertTextLinkRead' : 'alertTextLink'" @click="handleViewEdit(doc.eventId)">
          {{ doc.message }}
        </span>
        <span v-else :class="doc.read ? 'alertTextRead' : 'alertText'">{{ doc.message }}</span>
      </div>
      <button v-if="canShowMore" @click="showMoreAlerts" class="button-large-small-text stretch-item">
        Show More
      </button>
    </div>
  </div>
</template>

<script>
import getAlerts from '@/composables/getAlerts'
import updateAlertsReadAll from '@/composables/updateAlertsReadAll'
import { formatDistanceToNow } from 'date-fns'
import router from '@/router'
import Smash from '@/components/Smash.vue' // Adjust the path as necessary
import { ref, onMounted, computed, watchEffect, onUnmounted } from 'vue'
import usePlayerState from '@/composables/usePlayerState'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/firebase/config'

export default {
  name: 'show-alerts',
  components: {
    Smash,
  },
  setup() {
    document.body.scrollTop = document.documentElement.scrollTop = 0 // Always scroll to top
    // Wake-up call for Firestore function
    httpsCallable(functions, 'documentUpdates-setDocument')({ id: 'wakeUp' })

    const labelText = ref('Loading...')
    const labelSubtext = ref('almost done')
    const loadingData = ref(true)
    const { theme, playerData, waitForPlayerData } = usePlayerState()
    document.documentElement.setAttribute('data-theme', theme.value)
    const user_slug = ref('')
    const error = ref(null)
    const documents = ref([])
    const displayedCount = ref(10) // Start with 10 alerts

    onMounted(async () => {
      loadingData.value = true
      const success = await waitForPlayerData()
      loadingData.value = false

      if (success) {
        user_slug.value = playerData.value.id
        // Fetch alerts
        const alerts = getAlerts(user_slug.value) || { error: ref(null), documents: ref([]) }
        // Watch for updates and ensure properties exist before accessing `.value`
        watchEffect(() => {
          if (alerts.documents) {
            documents.value = alerts.documents.value || []
          }
          error.value = alerts.error?.value || null
        })
      }
    })

    const clearingAlerts = ref(false)

    // Compute formatted documents
    const documentsFormatted = computed(() => {
      if (documents.value) {
        return documents.value.map((doc) => {
          let time = formatDistanceToNow(doc.created.toDate())
          return { ...doc, created: time }
        })
      }
      return []
    })

    // Displayed subset of alerts
    const displayedAlerts = computed(() => {
      return documentsFormatted.value.slice(0, displayedCount.value)
    })

    // Determines if we should show "Show More" button
    const canShowMore = computed(() => {
      return displayedCount.value < documentsFormatted.value.length
    })

    // Loads 10 more alerts when "Show More" is clicked
    const showMoreAlerts = () => {
      displayedCount.value = Math.min(displayedCount.value + 10, documentsFormatted.value.length)
    }

    // Mark all messages as read
    const handleAllRead = async () => {
      clearingAlerts.value = true
      labelText.value = 'Updating Alerts'
      await updateAlertsReadAll(user_slug.value)
      clearingAlerts.value = false
    }

    const handleViewEdit = (e) => {
      router.push({ name: 'viewEditEventWithId', params: { evId: e } })
    }

    onUnmounted(() => {
      // Automatically tag all as read after player visits/leaves alerts
      handleAllRead()
    })

    return {
      error,
      documents,
      displayedAlerts,
      documentsFormatted,
      handleAllRead,
      handleViewEdit,
      clearingAlerts,
      loadingData,
      labelText,
      labelSubtext,
      user_slug,
      canShowMore,
      showMoreAlerts,
    }
  },
}
</script>

<style scoped>
.alertUnread {
  font-size: var(--font-size-subheading);
}

.alertTimestamp {
  display: block;
  font-size: var(--font-size-subheading);
  margin-bottom: 2px;
  padding-bottom: 0px;
  font-weight: 300;
  color: var(--text-color);
}

.alertTimestampRead {
  display: block;
  font-size: var(--font-size-subheading);
  margin-bottom: 2px;
  padding-bottom: 0px;
  font-weight: 300;
  color: var(--button-disabled-color);
}

.alertText {
  font-size: var(--font-size-subheading);
  font-weight: 500;
  color: var(--text-color);
  text-decoration: none;
}

.alertTextRead {
  font-size: var(--font-size-subheading);
  font-weight: 300;
  color: var(--button-disabled-color);
  text-decoration: none;
}

.alertTextLink {
  cursor: pointer;
  font-size: var(--font-size-subheading);
  color: var(--text-color);
  text-decoration: underline;
  font-weight: 500;
}

.alertTextLinkRead {
  cursor: pointer;
  font-size: var(--font-size-subheading);
  color: var(--button-disabled-color);
  text-decoration: underline;
  font-weight: 300;
}

.alertWrapper {
  margin-bottom: var(--margin-medium);
}
</style>