<template>
  <Smash :isVisible="loadingPlayers"></Smash>
  <div v-if="!isUpdating" class="eventOuterWrapper" style="margin-top: 0px;">
    <div class="normal-heading stretch-item" style="margin-top: var(--margin-small);">
      <i class="fa-solid fa-people-group"></i>Player List
    </div>
    <div class="normal-subheading2 stretch-item" style="margin-top: 0; margin-bottom: var(--margin-medium); font-style: italic;">*Some profiles are hidden</div>

    <div v-if="locationNames.length" class="eventFormBlock">
      <div class="eventInputLabel">
        {{ $t("event_location") }}
        <i class="fa-solid fa-location-dot"></i>
      </div>
      <select name="playersLocation" v-model="playersLocation" @change="changeLocation(playersLocation)" lass="locationSelect">
        <option v-for="location in locationNames" :key="location.id" v-bind:value="location.id">{{ location.name.substring(0, 20) }}</option>
      </select>
    </div>

    <div class="eventTabs stretch-item">
      <button class="button-large-small-text" :style="!viewPlayers ? 'background: var(--list-background-even); color: var(--input-text-color);' : ''" style="margin-right: 2px" @click="togglePlayers">Players</button>
      <button class="button-large-small-text" :style="!viewClubs ? 'background: var(--list-background-even); color: var(--input-text-color);' : ''" style="margin-right: 2px" @click="toggleClubs">Clubs</button>
    </div>
    <div class="normal-spacer"></div>

    <div v-if="playerNames && viewPlayers" class="outer-wrapper">
      <!-- <div v-if="evPlayers.length" class="grid-container-attending"> -->
      <div class="locPlayerListContainer">
        <div class="locPlayerListPreheader" style="border-radius: var(--radius-small) 0 0 0;"></div>
        <div class="locPlayerListPreheader"></div>
        <div class="locPlayerListPreheader"></div>
        <div class="locPlayerListPreheader">
          <i class="fa-solid fa-user-group" :class="friendFilter ? 'isFriendYes' : ''" @click="handleShowFriend()" style="padding: 2px;"></i>
        </div>
        <div class="locPlayerListPreheader">
          <i class="fa-solid fa-heart" :class="favFilter ? 'isFavYes' : ''" @click="handleShowFav()" style="padding: 2px;"></i>
        </div>
        <div class="locPlayerListPreheader" style="border-radius: 0 var(--radius-small) 0 0;">
          <i class="fa-solid fa-crown" :class="adminFilter ? 'isAdminYes' : ''" @click="handleShowAdmin()" style="padding: 2px;"></i>
        </div>

        <div class="locPlayerListHeader">Player</div>
        <div class="locPlayerListHeader"></div>
        <div class="locPlayerListHeader">Skill</div>
        <div class="locPlayerListHeader">Friend</div>
        <div class="locPlayerListHeader">Fav</div>
        <div class="locPlayerListHeader">Admin</div>

        <template v-for="(pn, index) in paginatedPlayerNames" :key="pn">
          <!-- <div :class="index % 2 == 0 ? 'locPlayerListPlayer' : 'locPlayerListPlayer-odd'">
                <i class="fa-solid fa-crown" style="color: #a67c00;"></i>
          </div>-->

          <div style="text-align: left; padding-left: 2px" :class="index % 2 == 0
                ? 'locPlayerListPlayer playerLink'
                : 'locPlayerListPlayer-odd playerLink'
                " @click="handleGoPlayer(pn.playerName)">{{ pn.playerName }}</div>
          <div :class="index % 2 == 0 ? 'locPlayerListPlayer' : 'locPlayerListPlayer-odd'">
            <i class="fa-solid fa-messages" @click="handleGoThread(pn.playerName)" style="cursor: pointer; color: var(--button-color)"></i>
          </div>
          <div :class="index % 2 == 0 ? 'locPlayerListPlayer' : 'locPlayerListPlayer-odd'
                ">{{ pn.skillSelf ? pn.skillSelf : "???" }}</div>
          <div :class="index % 2 == 0 ? 'locPlayerListPlayer' : 'locPlayerListPlayer-odd'">
            <i class="fa-solid fa-user-group" :class="{
                  isFriendYes: playerFriends.includes(pn.id),
                  isFriendNo: !playerFriends.includes(pn.id),
                }" @click="tFriends(user_slug, pn.id, playerFriends.includes(pn.id))"></i>
          </div>
          <div :class="index % 2 == 0 ? 'locPlayerListPlayer' : 'locPlayerListPlayer-odd'">
            <i class="fa-solid fa-heart" :class="{
                  isFavYes: playerFavs.includes(pn.id),
                  isFavNo: !playerFavs.includes(pn.id),
                }" @click="tFav(user_slug, pn.id, playerFavs.includes(pn.id))"></i>
          </div>

          <div :class="index % 2 == 0 ? 'locPlayerListPlayer' : 'locPlayerListPlayer-odd'">
            <i class="fa-solid fa-crown" :class="{
                  isAdminYes: locationAdmins.includes(pn.id),
                  isAdminNo: !locationAdmins.includes(pn.id),
                }" @click="handleAdmins(pn.id, locationAdmins.includes(user_slug))"></i>
          </div>
        </template>
      </div>
      <!-- pages -->
      <!-- <div class="eventTabs stretch-item"> -->
      <div class="eventTabs stretch-item">
        <div v-if="currentPage !== 1" class="button-large-small-text" @click="previousPage" style="margin-right: 2px">
          <i class="fa-solid fa-arrow-left"></i> Previous
        </div>
        <div v-if="totalPages > 0 && currentPage < totalPages - 0" class="button-large-small-text" @click="nextPage" style="margin-left: 2px">
          Next
          <i class="fa-solid fa-arrow-right"></i>
        </div>
      </div>
      <div class="normal-subheading2 stretch-item">Page {{ currentPage }} of {{ totalPages }}</div>
    </div>

    <div v-else class="outer-wrapper">
      <!-- <div v-if="evPlayers.length" class="grid-container-attending"> -->
      <button class="button-large-small-text stretch-item" @click="toggleCreateClub">
        <i class="fa-solid fa-file-plus"></i> Create Club
      </button>
      <!-- create clubs dialogue -->
      <div v-if="createClub" class="outer-wrapper">
        <div class="normal-spacer"></div>
        <div class="eventFormBlock">
          <div class="eventInputLabel">Name:</div>
          <input name="newClubName" style="text-align: left" type="text" v-model="v$.newClubName.$model" placeholder="Input club name" />
        </div>
        <div class="input-block-err" v-if="v$.newClubName.$error">
          <span v-for="error of v$.newClubName.$errors" :key="error.$uid">
            {{
            error.$message
            }}
          </span>
        </div>
        <div class="eventflex">
          <button class="button-large-small-text" style="margin-right: 2px" @click="addClub(v$.newClubName.$model, v$.newClubName.$error)">Save</button>
          <button class="button-large-small-text" style="margin-left: 2px" @click="toggleCreateClub">Cancel</button>
        </div>
        <div class="normal-spacer"></div>
      </div>
      <div class="normal-spacer"></div>

      <!-- display clubs list -->
      <div v-if="clubNames.length" class="locClubContainer">
        <div class="locClubHeader">Name</div>
        <div class="locClubHeader">Members</div>
        <div class="locClubHeader">Edit</div>
        <div class="locClubHeader">Delete</div>
        <template v-for="(cn, index) in clubNames" :key="cn">
          <div style="text-align: left" :class="index % 2 == 0 ? 'locClubClub' : 'locClubClub-odd'">{{ cn.clubName }}</div>
          <div :class="index % 2 == 0 ? 'locClubClub' : 'locClubClub-odd'">{{ cn.members ? cn.members : "0" }}</div>
          <div :class="index % 2 == 0 ? 'locClubClub' : 'locClubClub-odd'">
            <i class="fa-solid fa-file-pen" @click="handleEditClub(cn.id, cn.clubName, playersLocation)" style="cursor: pointer; color: var(--button-color);"></i>
          </div>
          <div :class="index % 2 == 0 ? 'locClubClub' : 'locClubClub-odd'">
            <i class="fa-solid fa-trash" @click="handleDeleteClub(cn.id)" style="cursor: pointer; color: var(--delete-cancel-color);"></i>
          </div>
        </template>
        <div v-if="clubNames.length" class="locClubClub-footer">{{ clubNames.length }} clubs</div>
      </div>
    </div>
  </div>
  <div v-else class="eventOuterWrapper">
    <div class="normal-heading">Loading...</div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { db, functions } from '@/firebase/config'
import { getDoc, setDoc, doc, serverTimestamp, collection, query, where, getDocs, orderBy, documentId, addDoc } from 'firebase/firestore'
import getUser from '@/composables/getUser'
import router from '@/router'
import slugify from 'slugify'
import { maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { format } from 'date-fns'
import usePlayersForLocation from '@/composables/usePlayersForLocation'
import { httpsCallable } from 'firebase/functions'
import Smash from '@/components/Smash.vue' // Adjust the path as necessary
import { useStore } from 'vuex'
import { favoriteLocationsReady } from '@/composables/favoriteLocationsReady' // wait indicator
import { playerFriendsReady } from '@/composables/playerFriendsReady' // loading state waiter
import { playerFavoritesReady } from '@/composables/playerFavoritesReady' // loading state waiter
import useToggleFav from '@/composables/useToggleFav'
import useToggleFriend from '@/composables/useToggleFriend'

export default {
  name: 'view-players-for-location',
  props: ['locId', 'clubActive', 'showAdmin'],
  components: {
    Smash,
  },
  setup(props) {
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always sc
    const { user_slug, user_name } = getUser()
    const store = useStore()
    const locationNames = ref([])
    const { toggleFav } = useToggleFav()
    const { toggleFriend } = useToggleFriend()

    const playersLocation = ref(props.locId)
    const playersLocationLabel = ref('')
    const playerNames = ref([])
    const playerCount = ref(0)
    const playerTotal = ref(0)
    const clubNames = ref([])
    const playerFavs = ref([])
    const playerFriends = ref([])
    const isUpdating = ref(false)
    const viewPlayers = ref(true)
    const viewClubs = ref(false)
    const newClubName = ref('')
    const v$ = useVuelidate()
    const createClub = ref(false)
    const currentPage = ref(1)
    const itemsPerPage = ref(10)
    const playerLabel = ref('players')
    // get location non-slug
    const getLocation = async () => {
      // DEBT: should be getting this from the store!
      const docRef = doc(db, 'locations', props.locId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        playersLocationLabel.value = docSnap.data().name
        // console.log("Document data:", docSnap.data().name);
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
    }
    getLocation()

    // togglePlayers
    const togglePlayers = () => {
      viewPlayers.value = true
      viewClubs.value = false
    }

    // toggleClubs
    const toggleClubs = () => {
      viewPlayers.value = false
      viewClubs.value = true
    }

    if (props.clubActive) {
      toggleClubs()
    }

    // toggleCreateClub
    const toggleCreateClub = () => {
      createClub.value = !createClub.value
    }

    const { getPlayersForLocation } = usePlayersForLocation(playersLocation, playerNames, playerCount, playerTotal)

    const loadingPlayers = ref(false)

    watch(
      () => store.state.myFriendsRaw, // Watch this computed value
      (newFriends) => {
        playerFriends.value = newFriends
      },
      { immediate: true }
    )

    watch(
      () => store.state.myFavoritesRaw, // Watch this computed value
      (newFavs) => {
        playerFavs.value = newFavs
      },
      { immediate: true }
    )

    onMounted(async () => {
      loadingPlayers.value = true
      console.log('MOUNTED')
      await getPlayersForLocation()
      console.log('DONE')
      try {
        await favoriteLocationsReady(store) // Waits until myLocationsRawLoaded is true or throws an error after 10 seconds
        locationNames.value = store.state.myLocations
        // console.log('❤️ Favorite locations are ready!', locationNames.value, store.state.myLocationsRawLoaded)
      } catch (error) {
        console.error('Failed to wait for favorite locations:', error)
      }

      // playerFriendsReady loaded
      try {
        await playerFriendsReady(store) // Waits until myFriendsRawLoaded is true or throws an error after 10 seconds
        playerFriends.value = store.state.myFriendsRaw
      } catch (error) {
        console.error('Failed to wait for friends:', error)
      }

      // playerFavoritesReady loaded
      try {
        await playerFavoritesReady(store) // Waits until myFavoritesRawLoaded is true or throws an error after 10 seconds
        playerFavs.value = store.state.myFavoritesRaw
      } catch (error) {
        console.error('Failed to wait for favorites:', error)
      }

      loadingPlayers.value = false
    })

    // get clubNames for this player
    const getClubsForLocation = async () => {
      let results = []
      const q = query(collection(db, 'clubs'), where('playerId', '==', user_slug.value), where('location', '==', playersLocation.value), where('owner', '==', true))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        results.push({ ...doc.data(), id: doc.id })
        // console.log(doc.id, " => ", doc.data());
      })
      clubNames.value = results
    }
    getClubsForLocation()

    //    addClub(n, err) {
    const addClub = async (cn, err) => {
      if (!err) {
        let clubNameId = slugify(cn, {
          replacement: '-',
          remove: /[$*_+~.()'"!\-:@]/g,
          lower: true,
        })

        const clubExists = await getDoc(doc(db, 'clubs', props.locId + clubNameId))
        if (clubExists.exists()) {
          // console.log("Club Exists:", clubExists.data());
          alert('Sorry, a club with this name already exists for ' + playersLocationLabel.value + '. Please choose another name.')
          return false // do nothing
        } else {
          // doc.data() will be undefined in this case
          // console.log("No club");

          const addClub = httpsCallable(functions, 'documentUpdates-setDocument')
          try {
            await addClub({
              collection: 'clubs',
              id: props.locId + clubNameId,
              merge: true,
              fields: {
                clubName: cn,
                playerId: user_slug.value,
                created: serverTimestamp(),
                location: playersLocation.value,
                members: 1,
                owner: true,
              },
            })
          } catch (error) {
            console.error(`Error adding club locatiion in cloud function documentUpdates-setDocument`, error)
          }

          newClubName.value = ''
          await getClubsForLocation()
          toggleCreateClub()
        }
        return true
      } else {
        return false
      }
    }

    const tFav = async (user_slug, pId, addFav) => {
      toggleFav(user_slug, pId, !addFav)
    }

    const tFriends = async (user_slug, pId, addFriend) => {
      toggleFriend(user_slug, pId, !addFriend)
    }

    const handleDeleteClub = async (id) => {
      try {
        // Delete all clubMembers records
        const qDelClub = query(collection(db, 'clubMembers'), where('clubId', '==', id))
        const querySnapshotClub = await getDocs(qDelClub)

        const deleteClubMembers = httpsCallable(functions, 'documentUpdates-deleteDocument')
        const deleteClubPromises = []

        querySnapshotClub.forEach((docMember) => {
          const deleteClubMemberPromise = deleteClubMembers({
            collection: 'clubMembers',
            id: docMember.id,
          }).catch((error) => {
            console.error(`Error deleting document ${docMember.id} from collection clubMembers in cloud function documentUpdates-deleteDocument`, error)
          })

          deleteClubPromises.push(deleteClubMemberPromise)
        })

        // Wait for all club members to be deleted
        await Promise.all(deleteClubPromises)

        // Delete the club itself
        const deleteClub = httpsCallable(functions, 'documentUpdates-deleteDocument')
        await deleteClub({
          collection: 'clubs',
          id: id,
        })
      } catch (error) {
        console.error(`Error deleting club ${id} and associated club members`, error)
      }

      // Additional logic after deletion, e.g., fetching updated club list
      getClubsForLocation()
    }

    // drafted
    const foundedDate = (dt) => {
      if (dt) {
        // console.log('DTV', format(new Date(dt.toDate()), "yyyy-MM-dd"))
        return format(new Date(dt.toDate()), 'MM/dd/yyyy')
      }
      // return format(new Date(dt), "yyyy-MM-dd")
    }
    const changeLocation = (nl) => {
      // console.log('NewLoc', nl)
      router.push({ name: 'players', params: { locId: nl } })
    }

    const filteredPlayerNames = computed(() => {
      if (favFilter.value) {
        playerTotal.value = playerFavs.value.length
        playerLabel.value = 'favorites'
        return playerNames.value.filter(
          (pn) => playerFavs.value.includes(pn.id) // Replace 'id' with the correct property
        )
      } else if (friendFilter.value) {
        playerTotal.value = playerFriends.value.length
        playerLabel.value = 'friends'
        return playerNames.value.filter(
          (pn) => playerFriends.value.includes(pn.id) // Replace 'id' with the correct property
        )
      } else if (adminFilter.value) {
        playerTotal.value = locationAdmins.value.length
        playerLabel.value = 'admins'
        return playerNames.value.filter(
          (pn) => locationAdmins.value.includes(pn.id) // Replace 'id' with the correct property
        )
      } else {
        playerLabel.value = 'players'
        playerTotal.value = playerNames.value.length
        return playerNames.value
      }
    })
    const paginatedPlayerNames = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value
      const end = start + itemsPerPage.value
      return filteredPlayerNames.value.slice(start, end)
    })

    const totalPages = computed(() => {
      // Use filteredPlayerNames.value.length instead of playerNames.value.length
      //   return Math.ceil(playerNames.value.length / itemsPerPage.value);
      return Math.ceil(filteredPlayerNames.value.length / itemsPerPage.value)
    })

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++
      }
    }

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--
      }
    }
    // new admin filter stuff
    const adminFilter = ref(false)
    const handleShowAdmin = () => {
      if (loadingPlayers.value) {
        alert('Please wait for players to load before applying a filter.')
        return
      }
      currentPage.value = 1
      friendFilter.value = false
      favFilter.value = false
      adminFilter.value = !adminFilter.value // Toggle the filter
    }

    // new fav filter stuff
    const favFilter = ref(false)
    const handleShowFav = () => {
      if (loadingPlayers.value) {
        alert('Please wait for players to load before applying a filter.')
        return
      }
      currentPage.value = 1
      friendFilter.value = false
      adminFilter.value = false
      favFilter.value = !favFilter.value // Toggle the filter
    }

    // new friend filter stuff
    const friendFilter = ref(false)
    const handleShowFriend = () => {
      if (loadingPlayers.value) {
        alert('Please wait for players to load before applying a filter.')
        return
      }
      currentPage.value = 1
      favFilter.value = false
      adminFilter.value = false
      friendFilter.value = !friendFilter.value // Toggle the filter
    }

    // get admins list flat array
    const locationAdmins = ref([])
    const getLocationAdmins = async () => {
      let results = []
      const qAdmin = query(collection(db, 'locationsAdmin'), where('locationId', '==', playersLocation.value))
      const querySnapshotqAdmin = await getDocs(qAdmin)
      querySnapshotqAdmin.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        results.push(doc.data().playerId)
      })
      locationAdmins.value = results
    }
    getLocationAdmins()

    const handleAdmins = async (pId, isAdmin) => {
      if (!isAdmin) {
        alert('Sorry, only an admin can promote another admin. Use the admin filter to view/contact admins for this location.')
        return
      } else {
        if (locationAdmins.value.includes(pId)) {
          alert(`${pId} is already an admin for this location.`)
          return
        }
        const inviteMessage = `[ADMIN INVITE] ${user_slug.value} would like to promote you to location admin at ${playersLocationLabel.value}.  Do you accept?`
        if (confirm(`Send ${pId} an invitation to become an admin at ${playersLocationLabel.value}?`)) {
          let messageDoc = {
            created: serverTimestamp(),
            // type: 'teamReq',
            type: 'adminReq',
            fromId: user_slug.value,
            from: user_slug.value,
            toId: pId,
            to: pId,
            channel: user_slug.value + pId,
            message: inviteMessage,
            locationId: playersLocation.value,
            read: false,
          }
          // add to messages
          addDoc(collection(db, 'messages'), messageDoc)
          // add/update to last message
          const docRef1 = doc(db, 'messageLast', user_slug.value + pId)
          // update last first channel (me)
          setDoc(docRef1, {
            created: serverTimestamp(),
            type: 'txt',
            fromId: user_slug.value,
            from: user_slug.value,
            toId: pId,
            to: pId,
            channel: user_slug.value + pId,
            message: '(' + user_slug.value + '):' + inviteMessage,
            read: false,
            channelPhotoURL: '',
            // channelPhotoURL: myPhotoURL.value ? myPhotoURL.value : ''
          })
          // update last second channel
          const docRef2 = doc(db, 'messageLast', pId + user_slug.value)
          setDoc(docRef2, {
            created: serverTimestamp(),
            type: 'txt',
            fromId: pId,
            from: pId,
            toId: user_slug.value,
            to: user_slug.value,
            channel: pId + user_slug.value,
            message: '(Me): ' + inviteMessage,
            read: true, // becasue I sent this
            channelPhotoURL: '',
            // channelPhotoURL: playerPhotoURL.value ? playerPhotoURL.value : ''
          })
          alert('An admin invitation has been sent to ' + pId + ' via in-App messages.')
        }
      }
    }

    return {
      playersLocation,
      playersLocationLabel,
      playerNames,
      playerFavs,
      tFav,
      playerFriends,
      tFriends,
      isUpdating,
      viewPlayers,
      viewClubs,
      togglePlayers,
      toggleClubs,
      clubNames,
      newClubName,
      v$,
      createClub,
      toggleCreateClub,
      addClub,
      handleDeleteClub,
      foundedDate,
      playerCount,
      playerTotal,
      locationNames,
      changeLocation,
      paginatedPlayerNames,
      currentPage,
      itemsPerPage,
      totalPages,
      nextPage,
      previousPage,
      handleShowFav,
      favFilter,
      friendFilter,
      handleShowFriend,
      locationAdmins,
      handleAdmins,
      adminFilter,
      handleShowAdmin,
      playerLabel,
      user_slug,
      loadingPlayers,
    }
  },
  methods: {
    handleGoPlayer(n) {
      router.push({
        name: 'viewplayer',
        params: {
          pName: slugify(n, {
            replacement: '-',
            remove: /[$*_+~.()'"!\-:@]/g,
            lower: true,
          }),
        },
      })
    },
    handleGoThread(n) {
      router.push({
        name: 'messagethread',
        params: {
          pName: slugify(n, {
            replacement: '-',
            remove: /[$*_+~.()'"!\-:@]/g,
            lower: true,
          }),
        },
      })
    },
    handleEditClub(id, name, loc) {
      router.push({
        name: 'editclub',
        params: {
          clubId: id,
        },
        query: {
          clubName: name,
          locId: loc,
        },
      })
    },
  },
  validations() {
    return {
      newClubName: { maxLengthValue: maxLength(12) },
    }
  },
}
</script>

<style scoped>
</style>