import { db } from "@/firebase/config";
import { doc, getDoc } from "firebase/firestore";
import slugify from "slugify";

const isInClub = async (uId, pId, locId, clubName) => {
    let booClub = false
    let clubMemberId = null;
    clubMemberId = locId + slugify(clubName, {
        replacement: "-",
        remove: /[$*_+~.()'"!\-:@]/g,
        lower: true,
    }) + pId
    let clubRef = doc(db, "clubMembers", clubMemberId);
    let clubSnap = await getDoc(clubRef);
    if (clubSnap.exists()) {
        booClub = true
        console.log('Club member')
        // console.log("Document data:", clubSnap.data());
    } else {
        // doc.data() will be undefined in this case
        console.log('NOT club member')
        booClub = false
    }
    return booClub
}
export default isInClub

