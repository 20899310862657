<template>
  <div class="menu-alert" style="padding-right: var(--padding-tiny);" @click="clickAlert">
    <i class="fa-solid fa-bell" aria-label="Notifications"></i>
    <span v-if="unreadCount > 0" class="notification-badge" aria-label="Unread notifications">{{ unreadCount }}</span>
  </div>
</template>
  
  <script>
  import { useRouter } from 'vue-router'

export default {
  name: 'MenuAlert',
  props: {
    unreadCount: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const router = useRouter()
    const clickAlert = (medal) => {
      router.push('/alerts')
    }
    return {
      clickAlert,
    }
  },
}
</script>
  
  