<template>
  <i class="fa-solid fa-magnifying-glass" aria-label="Search" @click="$emit('search')"></i>
</template>
  
  <script>
export default {
  name: 'MenuSearch',
}
</script>
  
  