import { ref, watchEffect } from 'vue'
// firebase imports
import { db } from '@/firebase/config'
import { collection, onSnapshot, query, where, orderBy, limit } from 'firebase/firestore'

const getMessages = (fromId, toId) => { 
    const documents = ref(null)
    const error = ref(null)
    //collection reference
    let collectionRef = collection(db, 'messages')
    collectionRef = query(collectionRef, where("channel", "in", [fromId + toId, toId + fromId]))
    collectionRef = query(collectionRef, orderBy("created", "desc"), limit(99))
    // collectionRef = query(collectionRef, limit("10"))
// update all as read

// now get
    const unsub = onSnapshot(collectionRef, snapshot => {
        // console.log('snapshot taken')
        let results = []
        snapshot.docs.forEach(doc => {
            doc.data().created && results.push({ ...doc.data(), id: doc.id })
        })
        // update values
        documents.value = results
        error.value = null
    }, (err) => {
        console.log(err.message)
        documents.value = null
        error.value = 'could not get data'
    })
    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())

    })
    return { documents, error }
}


export default getMessages