<template>
  <Smash :isVisible="showLoading" />
  <ModalPopup v-model="isModalVisible" :message="modalMessage" :heading="modalHeading" />
  <div v-if="!showLoading" class="outer-wrapper">
    <i class="fa-solid fa-key info-icon-large"></i>
    <div class="normal-heading">Reset password</div>
    <div class="normal-subheading">Enter your email address then tap "Send Reset".</div>
    <!-- <form @submit.prevent="handleSubmit()"> -->
    <!-- <div class="form-block">
        <label>{{ $t('emailInput') }}:</label>
      </div>

      <div class="form-block">
        <label id="icon" for="email">
          <i class="fas fa-envelope"></i>
        </label>
        <input style="text-align: left" type="email" inputmode="email" autocomplete="email" name="email" :placeholder="$t('emailPlaceholder')" v-model="v$.email.$model" />
    </div>-->

    <div class="input-block-signin" id="emailFocus">
      <i class="fas fa-envelope"></i>
      <input type="email" inputmode="email" autocomplete="email" name="email" :placeholder="$t('emailPlaceholder')" v-model="v$.email.$model" />
    </div>

    <div class="input-block-err" v-if="v$.email.$error">
      <span v-for="error of v$.email.$errors" :key="error.$uid">{{ error.$message }}</span>
    </div>

    <div class="input-block-err">
      <span v-if="errorMessage">{{ errorMessage }}</span>
    </div>
    <div class="normal-spacer"></div>
    <button class="button-large-small-text" @click="handleSubmit()">{{ $t('sendResetLink') }}</button>
    <div class="normal-spacer"></div>
    <button class="button-large-small-text" @click="handleSignin">{{ $t('backToSignin') }}</button>
    <!-- </form> -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/firebase/config'
import Smash from '@/components/Smash.vue'
import ModalPopup from '@/components/ModalPopup.vue'
import { useModal } from '@/composables/useModal'

export default {
  components: {
    Smash,
    ModalPopup,
  },
  setup() {
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always scroll to top on load
    const { isModalVisible, modalMessage, modalHeading, showModal } = useModal()
    const v$ = useVuelidate()
    const email = ref('')
    const showLoading = ref(false)
    const router = useRouter()
    const errorMessage = ref(null)
    const handleSignin = () => {
      router.push('/signin')
    }

    const handleSubmit = async () => {
      try {
        showLoading.value = true

        // Validate the form
        const isFormCorrect = await v$.value.$validate()
        if (!isFormCorrect) {
          console.log('Incorrect')
          showLoading.value = false
          return
        }

        // Send reset password email
        const auth = getAuth()
        const resetPassword = httpsCallable(functions, 'emails-emailPasswordReset')
        await resetPassword({ email: email.value })

        // Navigate to signin page if successful
        router.push('/signin')
      } catch (error) {
        // Handle errors gracefully
        errorMessage.value = error.message
        await showModal('Click OK to try again or go back and use another sign in method.', 'Reset Failed.')
        // router.push('/signin')
      } finally {
        // Always stop loading animation
        showLoading.value = false
      }
    }

    return {
      email,
      errorMessage,
      v$,
      showLoading,
      handleSubmit,
      handleSignin,
      isModalVisible,
      modalMessage,
      modalHeading,
    }
  },
  validations() {
    return {
      email: { email, required },
    }
  },
}
</script>

