import { db } from "@/firebase/config";
import { collection, getDocs, query, where } from "firebase/firestore";

const isFavoriteOfPlayer = async (uId, pId) => {
    let refFavorites = collection(db, 'playerFavorites')
    let booFavorite = false

    refFavorites = query(refFavorites, where("playerId", "==", uId), where("favoriteId", "==", pId))
    const FavoriteSnap = await getDocs(refFavorites);
    if (FavoriteSnap.empty) {
        // console.log('I am not a Favorite :(')
        booFavorite = false
    } else {
        FavoriteSnap.forEach((frDoc) => {
            // Favorite.data() is never undefined for query ev snapshots
            // console.log('Favorite??', frDoc.data().favorite)
            if (frDoc.data().favorite) {
                // console.log('I am a Favorite! :)')
                booFavorite = true
            } else {
                // console.log('I am no longer a Favorite :(')
                booFavorite = false
            }
            // console.log('YO', frDoc.id, " => ", frDoc.data());
        });
    }
    return booFavorite
}
export default isFavoriteOfPlayer

