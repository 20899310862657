<template>
  <Smash :isVisible="loadingData" :labelText="labelText" :labelSubtext="labelSubtext" />

  <ShowMessageThread v-if="!loadingData && user_slug && pName" :user_slug="user_slug" :user_name="user_name" :pName="pName" />
</template>

<script>
import ShowMessageThread from '@/components/ShowMessageThread.vue'
import Smash from '@/components/Smash.vue'
import { ref, onMounted } from 'vue'
import usePlayerState from '@/composables/usePlayerState'

export default {
  name: 'show-message-thread',
  components: {
    ShowMessageThread,
    Smash,
  },
  props: {
    pName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // issue is here u need to get pName from the router/prop then pass it to ShowMessageThread
    document.body.scrollTop = document.documentElement.scrollTop = 0 // always scroll to top on load
    const labelText = ref('Loading...')
    const labelSubtext = ref('almost done')
    const user_slug = ref('')
    const user_name = ref('')
    const loadingData = ref(true)
    const { theme, playerData, waitForPlayerData } = usePlayerState()
    document.documentElement.setAttribute('data-theme', theme.value)
    onMounted(async () => {
      loadingData.value = true
      const success = await waitForPlayerData()
      loadingData.value = false
      if (success) {
        user_slug.value = playerData.value.id
        user_name.value = playerData.value.playerName
      }
    })
    return {
      labelText,
      labelSubtext,
      user_slug,
      loadingData,
      user_name,
    }
  },
}
</script>

<style>
</style>