import { db } from "@/firebase/config";
import { collection, getDocs, query, where } from "firebase/firestore";

const isFriendOfPlayer = async (uId, pId) => {
    let refFriends = collection(db, 'playerFriends')
    let booFriend = false

    refFriends = query(refFriends, where("playerId", "==", uId), where("friendId", "==", pId))
    const friendSnap = await getDocs(refFriends);
    if (friendSnap.empty) {
        booFriend = false
    } else {
        friendSnap.forEach((frDoc) => {
            // friend.data() is never undefined for query ev snapshots
            if (frDoc.data().friend) {
                booFriend = true
            } else {
                booFriend = false
            }
        });
    }
    return booFriend
}
export default isFriendOfPlayer

