import { ref } from "vue";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

export function useImageUploader() {
  const progress = ref(0);
  const uploading = ref(false);
  const uploadedURL = ref(null);

  const uploadImage = async (blob, storagePath) => {
    try {
      uploading.value = true;
      const storage = getStorage();
      const fileRef = storageRef(storage, storagePath);

      // Upload the file
      const snapshot = await uploadBytes(fileRef, blob);

      // Get the download URL
      const url = await getDownloadURL(snapshot.ref);
      uploadedURL.value = url;

      return url;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    } finally {
      uploading.value = false;
    }
  };

  return {
    progress,
    uploading,
    uploadedURL,
    uploadImage,
  };
}
