import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { auth } from '@/firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import Cookies from 'js-cookie';

import '@/assets/main.css'; // global css
import './registerServiceWorker';

// new to disable/remove PWA
if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
}



const savedLanguage = Cookies.get('language') || 'en'; // Get the user's language preference from the cookie, default to English if no cookie exists




const i18n = createI18n({
  locale: savedLanguage, // Use the saved language preference
  fallbackLocale: 'en',
  messages: {
    en,
    es,
  },
  legacy: false, // Set this to false to disable the legacy mode
});

let app;
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = createApp(App).use(store).use(router).use(i18n).mount('#app');
    // Dispatch trackAuthState action after the app is created
    store.dispatch('trackAuthState');
  }

  if (user) {
    store.dispatch('startPlayerListener', user.uid); // Start the player listener after user logs in
    store.dispatch('startVersionListener'); // start version listener
  }

});
