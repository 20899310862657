// src/composables/noDoubletap.js
import { onMounted, onUnmounted } from 'vue'

export default function useNoDoubletap() {
  let lastTouchEnd = 0

  const handleTouchEnd = (event) => {
    const now = new Date().getTime()
    if (now - lastTouchEnd <= 300) {
      event.preventDefault() // Prevent double-tap zoom
    }
    lastTouchEnd = now
  }

  onMounted(() => {
    document.addEventListener('touchend', handleTouchEnd)
  })

  onUnmounted(() => {
    document.removeEventListener('touchend', handleTouchEnd)
  })
}
